import React, { forwardRef, useEffect } from "react";
import "components/Dropdowns/index.css";
import DropdownsPage from "components/DropdownsPage/DropdownsPage";

const MegaDropdown = forwardRef(
  (
    {
      className,
      data,
      features,
      index,
      heading,
      onMouseEnter,
      onMouseLeave,
      OnItemClick,
    },
    ref
  ) => {
    return (
      <div ref={ref} className={className}>
        <div
          className="container mega-container animated-box  animate_animated  animate_bounceInUp"
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <DropdownsPage
            data={data}
            features={features}
            index={index}
            heading={heading}
            onClick={(item, index) => OnItemClick(item, index)}
          />
        </div>
      </div>
    );
  }
);

export default MegaDropdown;
