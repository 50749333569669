import PhoneImg from "assets/img/main-img-1.png";
import FuelAdblueUsageimg from "assets/img/Fuel-Adblu-Usage-mobile-1.png";
import TorqueRegisterimg from "assets/img/Torque-Register-mobile-1.png";
import DriverChecksimg from "assets/img/Driver-Checks-mobile-1.png";
import RequestADemo from "assets/img/Vehicle-Alignment.jpeg";
import HerosectionING from "assets/img/Industries-driving-img.png";
import EnhancedSafetyING from "assets/img/Enhanced-Safety.png";
import WantToSee from "assets/img/Industries-features-img.png";
import BenefitsImg from "assets/img/Industries-benefits-img.png";

export const Header_Data = [
  {
    link_title: "Products",
    route: "/products",
    heading: "Featured",
    // feauters: ["AI Dashcam", "AI Dashcam", "AI Dashcam", "AI Dashcam"],
    multiRoutes: [
      // Driving History Tracking Page 1
      {
        heading: "Driving History Tracking",
        route_url: "driving-history-tracking",
        Main_title:
          "Driving History <br/> <span style='color: #7ebcb1;'>Tracking</span>",

        Main_Description:
          "<strong>Driving history tracking</strong> is essential for effective fleet management. Our advanced tracking solutions empower businesses to monitor driving behavior, enhance safety standards, and improve operational efficiency.",
        Main_img: PhoneImg,
        // Key Features1
        KeyFeatures_firstImg: DriverChecksimg,
        KeyFeatures_firstHeading: "Real-Time Location Tracking",
        KeyFeatures_firstDescription:
          "Access live GPS data to monitor vehicle locations continuously.",
        KeyFeatures_secondImg: TorqueRegisterimg,
        KeyFeatures_secondHeading: "Historical Data Analysis",
        KeyFeatures_secondDescription:
          "Review past trips, speeds, and routes to identify patterns and improve safety measures.",
        KeyFeatures_thirdImg: FuelAdblueUsageimg,
        KeyFeatures_thirdHeading: "Driver Behavior Assessment",
        KeyFeatures_thirdDescription:
          "Analyze driving behaviors, such as acceleration, braking, and cornering, to promote safe driving habits.",
        // Benefits1
        Benefits_firstImg: '<i class="fa fa-shield" aria-hidden="true"></i>',
        Benefits_firstHeading: "Enhanced <br>Safety",
        Benefits_firstDescription:
          "Track driving habits to minimise risky behaviours and ensure safer driving practices.",
        Benefits_secondImg: '<i class="fa fa-shield" aria-hidden="true"></i>',
        Benefits_secondHeading: "Increased <br>Accountability",
        Benefits_secondDescription:
          " Provide drivers with performance feedback, fostering a culture of responsibility within your fleet.",
        Benefits_thirdImg: '<i class="fa fa-shield" aria-hidden="true"></i>',
        Benefits_thirdHeading: "Insurance Cost <br>Savings",
        Benefits_thirdDescription:
          "Lower accident rates can result in reduced insurance premiums for your fleet.",
        Benefits_4thImg: '<i class="fa fa-shield" aria-hidden="true"></i>',
        Benefits_4thHeading: "Regulatory <br>Compliance",
        Benefits_4thDescription:
          "Maintain accurate driving records to comply with transportation regulations.",
        // Use Cases1
        UseCases_firstImg: RequestADemo,
        UseCases_firstHeading: "Fleet Managers",
        UseCases_firstDescription:
          "Utilize driving history data for targeted driver training and risk mitigation.",
        UseCases_secondImg: RequestADemo,
        UseCases_secondHeading: "Insurance Providers",
        UseCases_secondDescription:
          "Access comprehensive driving history to assess risk more accurately.",
        UseCases_thirdImg: RequestADemo,
        UseCases_thirdHeading: "Logistics Companies",
        UseCases_thirdDescription:
          "Optimize routing based on historical performance, enhancing delivery efficiency.",
        // GettingStarted1
      GettingStartedMainHeading:"Implementing driving history tracking in your fleet is simple",
        Getting_firstImg: RequestADemo,
        Getting_firstHeading: "Request a Demo",
        Getting_firstDescription:
          "Schedule a personalised demonstration to see our tracking solutions in action.",
        Getting_secondImg: RequestADemo,
        Getting_secondHeading: "Choose Your Plan",
        Getting_secondDescription:
          "Select from flexible pricing options tailored to your needs.",
        Getting_thirdImg: RequestADemo,
        Getting_thirdHeading: "Integrate Seamlessly",
        Getting_thirdDescription:
          "Our team will assist you in integrating the tracking solution into your existing fleet management system.",
      },
      // GPS Vehicle Tracking Page 2
      {
        heading: "GPS Vehicle Tracking",
        route_url: "gps-vehicle-tracking",
        Main_title:
          "GPS Vehicle<br/> <span style='color: #7ebcb1;'>Tracking</span>",

        Main_Description:
          "<strong>GPS vehicle tracking</strong> is a vital technology for modern fleet management, providing real-time monitoring and enhanced visibility of your vehicles. Our cutting-edge solutions help businesses optimise operations, improve safety, and reduce costs.",
        Main_img: PhoneImg,
        // Key Features2
        KeyFeatures_firstImg: DriverChecksimg,
        KeyFeatures_firstHeading: "Real-Time Monitoring",
        KeyFeatures_firstDescription:
          "Track vehicle locations in real-time using advanced GPS technology to ensure fleet efficiency.",
        KeyFeatures_secondImg: TorqueRegisterimg,
        KeyFeatures_secondHeading: "Historical Route Data",
        KeyFeatures_secondDescription:
          " Access past vehicle routes and driving data to analyse performance and improve future planning.",
        KeyFeatures_thirdImg: FuelAdblueUsageimg,
        KeyFeatures_thirdHeading: "Alerts & Notifications",
        KeyFeatures_thirdDescription:
          "Set up customisable alerts for speeding, idling, or unauthorised use to maintain control over your fleet.",
        // Benefits2
        Benefits_firstImg: '<i class="fa fa-shield" aria-hidden="true"></i>',
        Benefits_firstHeading: "Improved Fleet <br>Efficiency",
        Benefits_firstDescription:
          "Optimize routes and minimise downtime by accessing live traffic data and historical performance.",
        Benefits_secondImg: '<i class="fa fa-shield" aria-hidden="true"></i>',
        Benefits_secondHeading: "Enhanced<br>Security",
        Benefits_secondDescription:
          " Reduce theft risks with live tracking and options for geofencing alerts.",
        Benefits_thirdImg: '<i class="fa fa-shield" aria-hidden="true"></i>',
        Benefits_thirdHeading: "Cost<br>Savings",
        Benefits_thirdDescription:
          "Lower fuel costs and maintenance expenses through better route management and driver behaviour analysis.",
        Benefits_4thImg: '<i class="fa fa-shield" aria-hidden="true"></i>',
        Benefits_4thHeading: "Informed<br>Decisions",
        Benefits_4thDescription:
          "Utilize real-time data to make smart, informed decisions regarding your fleet operations.",
        // Use Cases2
        UseCases_firstImg: RequestADemo,
        UseCases_firstHeading: "Logistics Companies",
        UseCases_firstDescription:
          "Ensure timely deliveries by optimising routes and tracking shipment statuses effectively.",
        UseCases_secondImg: RequestADemo,
        UseCases_secondHeading: "Fleet Managers",
        UseCases_secondDescription:
          "Use GPS tracking to improve fleet monitoring and resource allocation.",
        UseCases_thirdImg: RequestADemo,
        UseCases_thirdHeading: "Small Business Owners",
        UseCases_thirdDescription:
          "Benefit from affordable GPS tracking solutions to enhance service delivery and customer satisfaction.",
        // GettingStarted2
      GettingStartedMainHeading:"To integrate GPS vehicle tracking into your fleet",
        Getting_firstImg: RequestADemo,
        Getting_firstHeading: "Request a Demo",
        Getting_firstDescription:
          "Schedule a live demonstration to experience our tracking solutions firsthand.",
        Getting_secondImg: RequestADemo,
        Getting_secondHeading: "Select Your Plan",
        Getting_secondDescription:
          "Choose from various flexible pricing packages based on your specific needs.",
        Getting_thirdImg: RequestADemo,
        Getting_thirdHeading: "Logistics Companies",
        Getting_thirdDescription:
          "Optimize routing based on historical performance, enhancing delivery efficiency.",
      },
      // Fleet Telematics Page 3
      {
        heading: "Fleet Telematics",
        route_url: "fleet-telematics",
        Main_title:
          "Fleet<br/> <span style='color: #7ebcb1;'>Telematics</span>",

        Main_Description:
          "<strong>Fleet telematics</strong> is at the forefront of modern fleet management, integrating advanced GPS tracking, telematics data, and analytics. At TecHGV, our solutions help businesses streamline operations, enhance vehicle performance, and improve safety measures, ensuring you stay ahead in a competitive landscape.",
        Main_img: PhoneImg,
        // Key Features3
        KeyFeatures_firstImg: DriverChecksimg,
        KeyFeatures_firstHeading: "Real-Time GPS Tracking",
        KeyFeatures_firstDescription:
          "Monitor vehicle locations in real-time, optimising routes for efficiency and reducing downtime.",
        KeyFeatures_secondImg: TorqueRegisterimg,
        KeyFeatures_secondHeading: "Comprehensive Vehicle Diagnostics",
        KeyFeatures_secondDescription:
          "Access critical data on engine health, tire pressure, and fuel efficiency to maintain optimal vehicle performance.",
        KeyFeatures_thirdImg: FuelAdblueUsageimg,
        KeyFeatures_thirdHeading: "Driver Behavior Monitoring",
        KeyFeatures_thirdDescription:
          "Analyze metrics such as acceleration, braking, and cornering to foster safer driving practices among your team.",
        // Benefits3
        Benefits_firstImg: '<i class="fa fa-shield" aria-hidden="true"></i>',
        Benefits_firstHeading: "Increased Operational <br>Efficiency",
        Benefits_firstDescription:
          " Leverage real-time data to optimize scheduling and routing, leading to significant time and cost savings.",
        Benefits_secondImg: '<i class="fa fa-shield" aria-hidden="true"></i>',
        Benefits_secondHeading: "Enhanced Safety <br>Protocols",
        Benefits_secondDescription:
          " Mitigate risks by identifying and addressing unsafe driving Behaviours, promoting a culture of safety across your fleet.",
        Benefits_thirdImg: '<i class="fa fa-shield" aria-hidden="true"></i>',
        Benefits_thirdHeading: "Cost <br>Management",
        Benefits_thirdDescription:
          "Reduce maintenance costs and fuel consumption through predictive maintenance and efficient driving practices.",
        Benefits_4thImg: '<i class="fa fa-shield" aria-hidden="true"></i>',
        Benefits_4thHeading: "Compliance and<br> Reporting",
        Benefits_4thDescription:
          "Simplify compliance with industry regulations with automated reporting and accurate record-keeping.",
        // Use Cases3
        UseCases_firstImg: RequestADemo,
        UseCases_firstHeading: "Logistics and Delivery Services",
        UseCases_firstDescription:
          "Ensure on-time deliveries and enhance customer satisfaction through real-time tracking and dynamic routing.",
        UseCases_secondImg: RequestADemo,
        UseCases_secondHeading: "Public Transportation Authorities",
        UseCases_secondDescription:
          "Monitor fleet utilisation and Optimise service delivery to meet community needs.",
        UseCases_thirdImg: RequestADemo,
        UseCases_thirdHeading: "Construction and Heavy Equipment",
        UseCases_thirdDescription:
          "Manage equipment usage and maintenance efficiently to minimize downtime and extend asset life.",
        // GettingStarted3
      GettingStartedMainHeading:"Integrating fleet telematics into your business is straightforward with TecHGV",
        Getting_firstImg: RequestADemo,
        Getting_firstHeading: "Request a Personalized Demo",
        Getting_firstDescription:
          "Experience our telematics solutions tailored to your fleet’s needs.",
        Getting_secondImg: RequestADemo,
        Getting_secondHeading: "Choose Your Custom Plan",
        Getting_secondDescription:
          "Select from scalable pricing options designed to grow your business.",
        Getting_thirdImg: RequestADemo,
        Getting_thirdHeading: "Seamless Integration Support",
        Getting_thirdDescription:
          "Our team provides comprehensive support, ensuring a smooth transition and integration with existing systems.",
      },
      // Fleet Management Page 4
      {
        heading: "Fleet Management",
        route_url: "fleet-management",
        Main_title:
          "Fleet <br/> <span style='color: #7ebcb1;'>Management</span>",

        Main_Description:
          "Effective <strong>fleet management</strong> is crucial for optimizing operations, reducing costs, and enhancing safety within your business. At TecHGV, we offer cutting-edge solutions that integrate technology and data analytics to empower fleet managers with the tools they need to succeed in a competitive market.",
        Main_img: PhoneImg,
        // Key Features4
        KeyFeatures_firstImg: DriverChecksimg,
        KeyFeatures_firstHeading: "Comprehensive Fleet Tracking",
        KeyFeatures_firstDescription:
          "Monitor vehicle locations, status, and performance in real-time to improve operational efficiency.",
        KeyFeatures_secondImg: TorqueRegisterimg,
        KeyFeatures_secondHeading: "Telematics Integration",
        KeyFeatures_secondDescription:
          "Access detailed data analytics on vehicle usage, driver Behaviour, and maintenance needs to make informed decisions.",
        KeyFeatures_thirdImg: FuelAdblueUsageimg,
        KeyFeatures_thirdHeading: "Fuel Management Solutions",
        KeyFeatures_thirdDescription:
          "Optimise fuel usage through monitoring practices and reporting tools that identify inefficiencies.",
        // Benefits4
        Benefits_firstImg: '<i class="fa fa-shield" aria-hidden="true"></i>',
        Benefits_firstHeading: "Cost <br>Savings",
        Benefits_firstDescription:
          "Reduce operational costs through efficient routing, fuel management, and proactive maintenance.",
        Benefits_secondImg: '<i class="fa fa-shield" aria-hidden="true"></i>',
        Benefits_secondHeading: "Improved  <br>Safety",
        Benefits_secondDescription:
          "Enhance driver safety with telematics and reporting tools that promote safer driving habits and compliance with regulations.",
        Benefits_thirdImg: '<i class="fa fa-shield" aria-hidden="true"></i>',
        Benefits_thirdHeading: "Increased  <br>Productivity",
        Benefits_thirdDescription:
          "Streamline operations and minimise downtime with real-time tracking and automated reporting.",
        Benefits_4thImg: '<i class="fa fa-shield" aria-hidden="true"></i>',
        Benefits_4thHeading: "Data-Driven <br> Insights",
        Benefits_4thDescription:
          "Utilise data analytics to make better decisions regarding resource allocation and fleet Optimisation.",
        // Use Cases4
        UseCases_firstImg: RequestADemo,
        UseCases_firstHeading: "Logistics Companies",
        UseCases_firstDescription:
          "Enhance delivery efficiency and customer satisfaction through Optimised routing and tracking.",
        UseCases_secondImg: RequestADemo,
        UseCases_secondHeading: "Construction Firms",
        UseCases_secondDescription:
          "Manage equipment fleets and ensure equipment is Utilised effectively and maintained regularly.",
        UseCases_thirdImg: RequestADemo,
        UseCases_thirdHeading: "Taxi and Ride-Sharing Services",
        UseCases_thirdDescription:
          "Improve ride efficiency and driver safety through real-time monitoring and analytics.",
        // GettingStarted4
        Getting_StartedMainHeading:"Integrating our fleet management solutions is simple",
        Getting_firstImg: RequestADemo,
        Getting_firstHeading: "Schedule a Free Demo",
        Getting_firstDescription:
          "Experience our innovative solutions tailored to your unique fleet needs.",
        Getting_secondImg: RequestADemo,
        Getting_secondHeading: "Select Your Plan",
        Getting_secondDescription:
          "Choose from various pricing options designed to cater to businesses of all sizes.",
        Getting_thirdImg: RequestADemo,
        Getting_thirdHeading: "Seamless Implementation",
        Getting_thirdDescription:
          "Our dedicated team will support you through the integration process for a smooth transition",
      },
      // Vehicle Checks Page 5
      {
        heading: "Vehicle Checks",
        route_url: "vehicle-checks",
        Main_title:
          "Vehicle <br/> <span style='color: #7ebcb1;'>Checks</span>",

        Main_Description:
          "<strong>Vehicle checks </strong> are essential for maintaining fleet integrity and ensuring safety on the road. At TecHGV, we provide advanced vehicle check solutions that integrate seamlessly with our fleet management system, helping you keep your vehicles in optimal condition.",
        Main_img: PhoneImg,
        // Key Features5
        KeyFeatures_firstImg: DriverChecksimg,
        KeyFeatures_firstHeading: "Comprehensive Inspection Processes",
        KeyFeatures_firstDescription:
          "Implement thorough pre-trip and post-trip inspections to ensure all vehicles meet safety standards.",
        KeyFeatures_secondImg: TorqueRegisterimg,
        KeyFeatures_secondHeading: "Automated Checklists",
        KeyFeatures_secondDescription:
          "Utilise Customisable checklists that streamline the inspection process for drivers and fleet managers.",
        KeyFeatures_thirdImg: FuelAdblueUsageimg,
        KeyFeatures_thirdHeading: "Real-Time Alerts",
        KeyFeatures_thirdDescription:
          "Receive immediate notifications for any identified issues during vehicle checks, enabling prompt action and repairs.",
        // Benefits5
        Benefits_firstImg: '<i class="fa fa-shield" aria-hidden="true"></i>',
        Benefits_firstHeading: "Enhanced <br>Safety",
        Benefits_firstDescription:
          "Regular vehicle checks help identify potential issues before they become safety hazards, protecting drivers and passengers.",
        Benefits_secondImg: '<i class="fa fa-shield" aria-hidden="true"></i>',
        Benefits_secondHeading: "Regulatory <br> Compliance",
        Benefits_secondDescription:
          "Stay compliant with transportation regulations by keeping accurate records of inspections and repairs.",
        Benefits_thirdImg: '<i class="fa fa-shield" aria-hidden="true"></i>',
        Benefits_thirdHeading: "Cost <br> Efficiency",
        Benefits_thirdDescription:
          "Early detection of vehicle issues can prevent costly repairs and minimise downtime due to breakdowns.",
        Benefits_4thImg: '<i class="fa fa-shield" aria-hidden="true"></i>',
        Benefits_4thHeading: "Informed <br>Decision-Making",
        Benefits_4thDescription:
          "Use inspection data to make informed decisions about vehicle utilization and maintenance needs.",
        // Use Cases5
        UseCases_firstImg: RequestADemo,
        UseCases_firstHeading: "Transportation Companies",
        UseCases_firstDescription:
          "Ensure fleet safety and compliance with regular inspections tailored for commercial vehicles.",
        UseCases_secondImg: RequestADemo,
        UseCases_secondHeading: "Public Sector Fleets",
        UseCases_secondDescription:
          "Maintain high safety standards and compliance with government regulations for public vehicles.",
        UseCases_thirdImg: RequestADemo,
        UseCases_thirdHeading: "Construction Fleets",
        UseCases_thirdDescription:
          " Regularly check heavy machinery and vehicles to prevent breakdowns and ensure worker safety.",
        // GettingStarted1
        Getting_StartedMainHeading:"Implementing effective vehicle checks is simple",
        Getting_firstImg: RequestADemo,
        Getting_firstHeading: "Request a Demo",
        Getting_firstDescription:
          "Schedule a personalised demonstration to see our vehicle check solutions in action.",
        Getting_secondImg: RequestADemo,
        Getting_secondHeading: "Select Your Plan",
        Getting_secondDescription:
          "Choose from Customisable plans that fit your fleet's specific needs.",
        Getting_thirdImg: RequestADemo,
        Getting_thirdHeading: "Easy Integration",
        Getting_thirdDescription:
          "Our team will assist you every step of the way to ensure smooth implementation.",
      },
      // Maintenance Planner Page 6
      {
        heading: "Maintenance Plannerg",
        route_url: "maintenance-planner",
        Main_title:
          "Maintenance<br/> <span style='color: #7ebcb1;'>Planner</span>",

        Main_Description:
          "A proactive <strong>Mintenance Planner</strong> is essential for ensuring your fleet operates smoothly and efficiently. At TecHGV, our maintenance planning solutions help you schedule, track, and manage maintenance tasks effectively, reducing downtime and extending the lifespan of your vehicles.",
        Main_img: PhoneImg,
        // Key Features1
        KeyFeatures_firstImg: DriverChecksimg,
        KeyFeatures_firstHeading: "Automated Maintenance Scheduling",
        KeyFeatures_firstDescription:
          " Set up alerts and reminders for regular service intervals based on mileage or time, ensuring timely maintenance.",
        KeyFeatures_secondImg: TorqueRegisterimg,
        KeyFeatures_secondHeading: "Digital Record Keeping",
        KeyFeatures_secondDescription:
          "Maintain a comprehensive digital log of all maintenance activities, including service history and costs, accessible at any time.",
        KeyFeatures_thirdImg: FuelAdblueUsageimg,
        KeyFeatures_thirdHeading: "Predictive Maintenance",
        KeyFeatures_thirdDescription:
          "Utilise data analytics to anticipate maintenance needs before they become critical, minimizing unexpected breakdowns.",
        // Benefits1
        Benefits_firstImg: '<i class="fa fa-shield" aria-hidden="true"></i>',
        Benefits_firstHeading: "Reduced <br>Downtime ",
        Benefits_firstDescription:
          "Proactively addressing maintenance needs helps minimise vehicle downtime, keeping your operations running smoothly.",
        Benefits_secondImg: '<i class="fa fa-shield" aria-hidden="true"></i>',
        Benefits_secondHeading: "Cost <br>Efficiency",
        Benefits_secondDescription:
          "Preventative maintenance reduces the likelihood of costly repairs and extends the lifespan of your assets.",
        Benefits_thirdImg: '<i class="fa fa-shield" aria-hidden="true"></i>',
        Benefits_thirdHeading: "Enhanced <br>Safety",
        Benefits_thirdDescription:
          "Regular maintenance checks help ensure vehicles meet safety standards, reducing the risk of accidents.",
        Benefits_4thImg: '<i class="fa fa-shield" aria-hidden="true"></i>',
        Benefits_4thHeading: "Streamlined <br>Operations",
        Benefits_4thDescription:
          "Automate scheduling and record-keeping, allowing fleet managers to focus on strategic decision-making rather than administrative tasks.",
        // Use Cases1
        UseCases_firstImg: RequestADemo,
        UseCases_firstHeading: "Logistics Providers",
        UseCases_firstDescription:
          "Ensure timely delivery by keeping delivery vehicles in top condition through effective maintenance scheduling.",
        UseCases_secondImg: RequestADemo,
        UseCases_secondHeading: "Public Sector Fleets",
        UseCases_secondDescription:
          "Maintain city and government vehicles to ensure they are always compliant and operational.",
        UseCases_thirdImg: RequestADemo,
        UseCases_thirdHeading: "Construction Companies",
        UseCases_thirdDescription:
          "Manage the maintenance of heavy machinery to prevent project delays due to equipment failure.",
        // GettingStarted1
        Getting_StartedMainHeading:
          "Implementing our maintenance planner is straightforward:",
        Getting_firstImg: RequestADemo,
        Getting_firstHeading: "Request a Demo",
        Getting_firstDescription:
          " See our maintenance planning solutions in action with a tailored demonstration.",
        Getting_secondImg: RequestADemo,
        Getting_secondHeading: "Choose Your Custom Plan",
        Getting_secondDescription:
          "Options are available to fit the specific needs of your fleet.",
        Getting_thirdImg: RequestADemo,
        Getting_thirdHeading: "Easy Integration",
        Getting_thirdDescription:
          "Our team provides full support to ensure seamless integration with your existing systems.",
      },
      // Service Event Reminders Page 7
      {
        heading: "Service Event Reminders",
        route_url: "service-event-reminders",
        Main_title: "Service Event <br/> <span style='color: #7ebcb1;'>Reminders</span>",

        Main_Description: "<strong>Service Event Reminders</strong> are crucial for maintaining the health and performance of your fleet. At TecHGV, our automated reminder system ensures that your vehicles receive the necessary maintenance on time, minimizing the risk of unexpected breakdowns and enhancing safety.",
        Main_img: PhoneImg,
        // Key Features7
        KeyFeatures_firstImg: DriverChecksimg,
        KeyFeatures_firstHeading: "Automated Alerts",
        KeyFeatures_firstDescription: "Set up automatic notifications for service events based on mileage, engine hours, or calendar dates, ensuring no maintenance is overlooked.",
        KeyFeatures_secondImg: TorqueRegisterimg,
        KeyFeatures_secondHeading: "Customisable Reminder Settings",
        KeyFeatures_secondDescription: "Tailor reminders to specific vehicles or service types, allowing flexibility to meet diverse fleet needs.",
        KeyFeatures_thirdImg: FuelAdblueUsageimg,
        KeyFeatures_thirdHeading: "Digital Service Logs",
        KeyFeatures_thirdDescription: "Maintain a comprehensive record of all service events, accessible anytime for tracking and compliance purposes.",
        // Benefits7
        Benefits_firstImg: '<i class="fa fa-shield" aria-hidden="true"></i>',
        Benefits_firstHeading: "Preventative <br>Maintenance",
        Benefits_firstDescription: "Timely reminders help prevent costly repairs and extend the lifespan of your vehicles.",
        Benefits_secondImg: '<i class="fa fa-shield" aria-hidden="true"></i>',
        Benefits_secondHeading: "Improved <br>Safety",
        Benefits_secondDescription: "Regular servicing ensures vehicles remain safe and compliant with regulations, protecting drivers and passengers alike.",
        Benefits_thirdImg: '<i class="fa fa-shield" aria-hidden="true"></i>',
        Benefits_thirdHeading: "Enhanced Workflow <br>Efficiency",
        Benefits_thirdDescription: "Automate administrative tasks related to service scheduling, freeing up time for fleet managers to focus on other priorities.",
        Benefits_4thImg: '<i class="fa fa-shield" aria-hidden="true"></i>',
        Benefits_4thHeading: "Cost <br>Control",
        Benefits_4thDescription: "Reduce the likelihood of emergency repairs through efficient service management, leading to lower overall operational costs.",
        // Use Cases7
        UseCases_firstImg: RequestADemo,
        UseCases_firstHeading: "Delivery Services",
        UseCases_firstDescription: "Ensure timely vehicle maintenance for logistics companies to maintain delivery schedules.",
        UseCases_secondImg: RequestADemo,
        UseCases_secondHeading: "Rental Fleets",
        UseCases_secondDescription: "Keep rental vehicles in optimal condition to enhance customer satisfaction and safety.",
        UseCases_thirdImg: RequestADemo,
        UseCases_thirdHeading: "Corporate Fleets",
        UseCases_thirdDescription: "Manage employee vehicles effectively with timely reminders that align with company policies.",
        // GettingStarted7
        Getting_StartedMainHeading: "Implementing service event reminders is simple",
        Getting_firstImg: RequestADemo,
        Getting_firstHeading: "Request a Demo",
        Getting_firstDescription: "Experience the benefits of our automated reminder system with a personalised demonstration.",
        Getting_secondImg: RequestADemo,
        Getting_secondHeading: "Select Your Plan",
        Getting_secondDescription: "Choose from various plans tailored to fit your fleet's requirements.",
        Getting_thirdImg: RequestADemo,
        Getting_thirdHeading: "Seamless Setup",
        Getting_thirdDescription: "Our support team will assist you in setting up the reminder system for effortless integration.",
      },
      // Personal Accident Coverage  Page 8
      {
        heading: "Personal Accident Coverage",
        route_url: "personal-accident-coverage",
        Main_title:
          "Personal Accident <br/> <span style='color: #7ebcb1;'>Coverage</span>",

        Main_Description:
          "<strong>Personal Accident Coverage</strong>Personal accident coverage is a vital component of fleet safety and compliance, protecting drivers and passengers in the event of an accident. At TecHGV, we offer comprehensive accident coverage solutions designed to provide peace of mind and financial protection for your fleet operations.",
        Main_img: PhoneImg,
        // Key Features8
        KeyFeatures_firstImg: DriverChecksimg,
        KeyFeatures_firstHeading: "Comprehensive Coverage Options",
        KeyFeatures_firstDescription:
          "Tailored plans that cover medical expenses, income loss, and other related costs in case of an accident.",
        KeyFeatures_secondImg: TorqueRegisterimg,
        KeyFeatures_secondHeading: "24/7 Support and Assistance",
        KeyFeatures_secondDescription:
          "Access to dedicated support for immediate assistance following an accident, ensuring timely care and guidance.",
        KeyFeatures_thirdImg: FuelAdblueUsageimg,
        KeyFeatures_thirdHeading: "Flexible Policy Terms",
        KeyFeatures_thirdDescription:
          "Choose the coverage limits and deductibles that best suit your business needs and risk profile.",
        // Benefits8
        Benefits_firstImg: '<i class="fa fa-shield" aria-hidden="true"></i>',
        Benefits_firstHeading: "Financial <br>Protection",
        Benefits_firstDescription:
          "Safeguard your drivers and your business from high medical costs and liability claims arising from accidents.",
        Benefits_secondImg: '<i class="fa fa-shield" aria-hidden="true"></i>',
        Benefits_secondHeading: "Enhanced Driver <br>Confidence",
        Benefits_secondDescription:
          "Knowing they are protected can boost driver morale and performance, leading to safer driving practices.",
        Benefits_thirdImg: '<i class="fa fa-shield" aria-hidden="true"></i>',
        Benefits_thirdHeading: "Regulatory <br>Compliance",
        Benefits_thirdDescription:
          "Adhere to industry regulations and ensure your fleet is prepared for any potential liabilities.",
        Benefits_4thImg: '<i class="fa fa-shield" aria-hidden="true"></i>',
        Benefits_4thHeading: "Risk <br>Mitigation",
        Benefits_4thDescription:
          "Invest in safety initiatives that reduce the likelihood of accidents, thus lowering the risk of claims.",
        // Use Cases8
        UseCases_firstImg: RequestADemo,
        UseCases_firstHeading: "Transportation Companies",
        UseCases_firstDescription:
          "Protect drivers during transit and ensure compliance with safety regulations.",
        UseCases_secondImg: RequestADemo,
        UseCases_secondHeading: "Corporate Fleets",
        UseCases_secondDescription:
          "Offer peace of mind for employees using company vehicles, enhancing overall safety culture.",
        UseCases_thirdImg: RequestADemo,
        UseCases_thirdHeading: "Delivery Services",
        UseCases_thirdDescription:
          "Safeguard delivery personnel against potential accidents while on the road.",
        // GettingStarted8
        Getting_StartedMainHeading:"Integrating personal accident coverage with your fleet management is easy",
        Getting_firstImg: RequestADemo,
        Getting_firstHeading: "Request a Consultation",
        Getting_firstDescription:
          "Schedule a meeting to discuss your coverage needs and options.",
        Getting_secondImg: RequestADemo,
        Getting_secondHeading: "Choose the Right Plan",
        Getting_secondDescription:
          "Work with our experts to select the most suitable policy for your fleet.",
        Getting_thirdImg: RequestADemo,
        Getting_thirdHeading: "Ongoing Support",
        Getting_thirdDescription:
          "Receive continuous support and resources to help maintain compliance and enhance safety.",
      },
      // Road Accident Management Page 9
      {
        heading: "Road Accident Management",
        route_url: "road-accident-management",
        Main_title:
          "Road Accident<br/> <span style='color: #7ebcb1;'>Management</span>",
        Main_Description:
          "Effective <strong>road accident management</strong> is crucial for maintaining safety and minimizing the impact of accidents on fleet operations. At TecHGV, we provide comprehensive solutions that focus on proactive accident management, ensuring your fleet is prepared to respond swiftly and efficiently in the event of an incident.",
        Main_img: PhoneImg,
        // Key Features9
        KeyFeatures_firstImg: DriverChecksimg,
        KeyFeatures_firstHeading: "Real-Time Accident Reporting",
        KeyFeatures_firstDescription:
          "Instant notification system to document accidents as they happen, allowing for quick response and data collection.",
        KeyFeatures_secondImg: TorqueRegisterimg,
        KeyFeatures_secondHeading: "Step-by-Step Incident Response",
        KeyFeatures_secondDescription:
          "Clear guidelines to assist drivers and fleet managers in managing accidents, from initial response to reporting.",
        KeyFeatures_thirdImg: FuelAdblueUsageimg,
        KeyFeatures_thirdHeading: "Accident Analysis Tools",
        KeyFeatures_thirdDescription:
          "Data analytics to assess accident trends, identify risk factors, and improve future safety measures.",
        // Benefits9
        Benefits_firstImg: '<i class="fa fa-shield" aria-hidden="true"></i>',
        Benefits_firstHeading: "Reduced <br>Downtime",
        Benefits_firstDescription:
          "Quick response to accidents minimises disruption and allows for faster recovery.",
        Benefits_secondImg: '<i class="fa fa-shield" aria-hidden="true"></i>',
        Benefits_secondHeading: "Enhanced Safety <br>Protocols",
        Benefits_secondDescription:
          "Proactive management leads to improved safety practices and a reduction in future incidents.",
        Benefits_thirdImg: '<i class="fa fa-shield" aria-hidden="true"></i>',
        Benefits_thirdHeading: "Cost <br>Efficiency",
        Benefits_thirdDescription:
          "Minimise repair and liability costs through effective accident management processes",
        Benefits_4thImg: '<i class="fa fa-shield" aria-hidden="true"></i>',
        Benefits_4thHeading: "Regulatory <br>Compliance",
        Benefits_4thDescription:
          "Ensure your operations meet industry standards for accident reporting and management.",
        // Use Cases9
        UseCases_firstImg: RequestADemo,
        UseCases_firstHeading: "Logistics and Transportation Companies",
        UseCases_firstDescription:
          "Efficiently manage road incidents to maintain delivery schedules and safety.",
        UseCases_secondImg: RequestADemo,
        UseCases_secondHeading: "Corporate Fleets",
        UseCases_secondDescription:
          "Ensure employee safety and minimise potential liabilities associated with accidents.",
        UseCases_thirdImg: RequestADemo,
        UseCases_thirdHeading: "Delivery Services",
        UseCases_thirdDescription:
          "Implement streamlined processes to handle accidents swiftly and maintain customer trust.",
        // GettingStarted9
        Getting_StartedMainHeading:"",
        Getting_firstImg: RequestADemo,
        Getting_firstHeading: "Schedule a Consultation",
        Getting_firstDescription:
          "Discuss your road accident management needs with our experts.",
        Getting_secondImg: RequestADemo,
        Getting_secondHeading: "Choose a Custom Solution",
        Getting_secondDescription:
          "Select from various management plans tailored to your fleet’s requirements.",
        Getting_thirdImg: RequestADemo,
        Getting_thirdHeading: "Ongoing Training and Support",
        Getting_thirdDescription:
          "Receive continuous guidance and resources to enhance your team's accident response skills.",
      },
      // Fuel & AdBlue Management Page 10
      {
        heading: "Fuel & AdBlue Management",
        route_url: "fuel-and-adblue-management",
        Main_title:
          "Fuel & AdBlue <br/> <span style='color: #7ebcb1;'>Management</span>",
        Main_Description:
          "Effective <strong>fuel and AdBlue management</strong> is essential for optimizing fleet performance and reducing operational costs. At TecHGV, we provide innovative solutions to streamline fuel usage and ensure compliance with environmental regulations, enhancing both efficiency and sustainability.",
        Main_img: PhoneImg,
        // Key Features10
        KeyFeatures_firstImg: DriverChecksimg,
        KeyFeatures_firstHeading: "Real-Time Fuel Monitoring",
        KeyFeatures_firstDescription:
          "Track fuel usage in real-time to identify trends, prevent fraud, and Optimise consumption.",
        KeyFeatures_secondImg: TorqueRegisterimg,
        KeyFeatures_secondHeading: "AdBlue Tracking and Management",
        KeyFeatures_secondDescription:
          "Ensure proper AdBlue levels for diesel vehicles, helping to maintain emissions compliance and engine performance.",
        KeyFeatures_thirdImg: FuelAdblueUsageimg,
        KeyFeatures_thirdHeading: "Automated Reporting Tools",
        KeyFeatures_thirdDescription:
          "Generate detailed reports on fuel usage, costs, and efficiency, enabling informed decision-making.",
        // Benefits10
        Benefits_firstImg: '<i class="fa fa-shield" aria-hidden="true"></i>',
        Benefits_firstHeading: "Cost <br>Savings",
        Benefits_firstDescription:
          "Optimise fuel consumption to significantly reduce overall operational costs.",
        Benefits_secondImg: '<i class="fa fa-shield" aria-hidden="true"></i>',
        Benefits_secondHeading: "Improved Environmental <br>Compliance",
        Benefits_secondDescription:
          "Ensure AdBlue usage aligns with emissions standards, avoiding potential fines and penalties.",
        Benefits_thirdImg: '<i class="fa fa-shield" aria-hidden="true"></i>',
        Benefits_thirdHeading: "Enhanced Operational <br>Efficiency",
        Benefits_thirdDescription:
          "Gain insights that help improve driving Behaviours and reduce fuel wastage.",
        Benefits_4thImg: '<i class="fa fa-shield" aria-hidden="true"></i>',
        Benefits_4thHeading: "Proactive<br> Maintenance Alerts",
        Benefits_4thDescription:
          "Monitor engine performance related to AdBlue systems and receive alerts for necessary maintenance.",
        // Use Cases10
        UseCases_firstImg: RequestADemo,
        UseCases_firstHeading: "Logistics and Transportation",
        UseCases_firstDescription:
          "Monitor fuel usage to enhance route efficiency and ensure timely deliveries without overspending.",
        UseCases_secondImg: RequestADemo,
        UseCases_secondHeading: "Corporate Fleets",
        UseCases_secondDescription:
          "Implement strategies for sustainable fuel management, aligning with corporate sustainability goals.",
        UseCases_thirdImg: RequestADemo,
        UseCases_thirdHeading: "Construction and Heavy Equipment",
        UseCases_thirdDescription:
          "Manage fuel consumption effectively to reduce costs on large machinery and equipment.",
        // GettingStarted10
      Getting_StartedMainHeading:"",
        Getting_firstImg: RequestADemo,
        Getting_firstHeading: "Schedule a Demo",
        Getting_firstDescription:
          " Experience our fuel and AdBlue management solutions firsthand with a personalised demonstration.",
        Getting_secondImg: RequestADemo,
        Getting_secondHeading: "Customise Your Plan",
        Getting_secondDescription:
          "Work with our team to develop a fuel strategy tailored to your fleet’s needs.",
        Getting_thirdImg: RequestADemo,
        Getting_thirdHeading: "Training and Support",
        Getting_thirdDescription:
          "Access ongoing training resources and customer support to Optimise your fuel management processes.",
      },
      // Transport Manager Reports Page 11
      {
        heading: "Transport Manager Reports",
        route_url: "transport-manager-reports",
        Main_title:
          "Transport Manager <br/> <span style='color: #7ebcb1;'>Reports</span>",
        Main_Description:
          "<strong>Transport Manager Reports </strong>provide critical insights into fleet performance, compliance, and operations, helping transport managers make informed decisions. At TecHGV, our reporting solutions empower managers with the data they need to enhance efficiency and drive safety.",
        Main_img: PhoneImg,
        // Key Features11
        KeyFeatures_firstImg: DriverChecksimg,
        KeyFeatures_firstHeading: "Comprehensive Reporting Dashboard",
        KeyFeatures_firstDescription:
          " Access a user-friendly dashboard that consolidates key performance indicators, making data analysis straightforward.",
        KeyFeatures_secondImg: TorqueRegisterimg,
        KeyFeatures_secondHeading: "Customisable Report Templates",
        KeyFeatures_secondDescription:
          "Create tailored reports that meet specific business needs, focusing on the metrics that matter most to your fleet.",
        KeyFeatures_thirdImg: FuelAdblueUsageimg,
        KeyFeatures_thirdHeading: "Automated Data Collection",
        KeyFeatures_thirdDescription:
          "Streamline data gathering from various sources, ensuring accuracy and saving valuable time.",
        // Benefits11
        Benefits_firstImg: '<i class="fa fa-shield" aria-hidden="true"></i>',
        Benefits_firstHeading: " Enhanced <br>Decision-Making",
        Benefits_firstDescription:
          "Data-driven insights help transport managers identify trends, Optimise routes, and improve operational efficiency.",
        Benefits_secondImg: '<i class="fa fa-shield" aria-hidden="true"></i>',
        Benefits_secondHeading: "Improved <br>Compliance",
        Benefits_secondDescription:
          "Monitor compliance with industry regulations and safety standards through detailed reporting.",
        Benefits_thirdImg: '<i class="fa fa-shield" aria-hidden="true"></i>',
        Benefits_thirdHeading: "Cost <br>Reduction",
        Benefits_thirdDescription:
          "Identify areas to reduce expenses by Analysing fuel usage, maintenance costs, and driver Behaviours.",
        Benefits_4thImg: '<i class="fa fa-shield" aria-hidden="true"></i>',
        Benefits_4thHeading: "Performance <br>Tracking",
        Benefits_4thDescription:
          "Set benchmarks and track fleet performance over time to ensure continuous improvement.",
        // Use Cases11
        UseCases_firstImg: RequestADemo,
        UseCases_firstHeading: "Logistics Companies",
        UseCases_firstDescription:
          "Streamline operations and enhance delivery efficiency while keeping costs in check.",
        UseCases_secondImg: RequestADemo,
        UseCases_secondHeading: "Corporate Fleets",
        UseCases_secondDescription:
          "Monitor employee driving patterns and vehicle usage to enhance safety and reduce liabilities.",
        UseCases_thirdImg: RequestADemo,
        UseCases_thirdHeading: "Public Transport Authorities",
        UseCases_thirdDescription:
          "Ensure compliance with transportation regulations and assess service quality through detailed reports.",
        // GettingStarted11
        Getting_StartedMainHeading:"",
        Getting_firstImg: RequestADemo,
        Getting_firstHeading: "Request a Demo",
        Getting_firstDescription:
          "Experience our reporting tools with a personalised demonstration tailored to your requirements.",
        Getting_secondImg: RequestADemo,
        Getting_secondHeading: "Set Up Reporting Parameters",
        Getting_secondDescription:
          "Work with our specialists to customise  the type of reporting that best suits your operational goals.",
        Getting_thirdImg: RequestADemo,
        Getting_thirdHeading: "Ongoing Support and Training",
        Getting_thirdDescription:
          "Access resources and expert assistance to maximize the use of your reporting tools.",
      },
      // Vehicle Reports Page 12
      {
        heading: "Vehicle Reports",
        route_url: "vehicle-reports",
        Main_title:
          "Vehicle <br/> <span style='color: #7ebcb1;'>Reports</span>",
        Main_Description:
          "<strong>Vehicle Reports</strong> provide vital information about individual vehicles within your fleet, offering insights that help improve efficiency, safety, and maintenance. At TecHGV, our reporting solutions enable fleet managers to make informed decisions, ensuring optimal vehicle performance and compliance.",
        Main_img: PhoneImg,
        // Key Features12
        KeyFeatures_firstImg: DriverChecksimg,
        KeyFeatures_firstHeading: "Detailed Vehicle Profiles",
        KeyFeatures_firstDescription:
          "Comprehensive data on each vehicle, including specifications, usage history, and maintenance records.",
        KeyFeatures_secondImg: TorqueRegisterimg,
        KeyFeatures_secondHeading: "Real-Time Monitoring",
        KeyFeatures_secondDescription:
          "Update reports with live data on vehicle performance, location, and fuel consumption to support proactive management.",
        KeyFeatures_thirdImg: FuelAdblueUsageimg,
        KeyFeatures_thirdHeading: "Customisable Reporting Options",
        KeyFeatures_thirdDescription:
          "Tailor reports to focus on specific metrics, such as vehicle health, usage patterns, and operational costs.",
        // Benefits12
        Benefits_firstImg: '<i class="fa fa-shield" aria-hidden="true"></i>',
        Benefits_firstHeading: "Enhanced Maintenance <br>Planning",
        Benefits_firstDescription:
          "Timely reports help schedule maintenance and repairs, reducing downtime and extending vehicle lifespan.",
        Benefits_secondImg: '<i class="fa fa-shield" aria-hidden="true"></i>',
        Benefits_secondHeading: "Cost <br>Efficiency",
        Benefits_secondDescription:
          "Analyze operational costs related to fuel, maintenance, and repairs to identify areas for potential savings.",
        Benefits_thirdImg: '<i class="fa fa-shield" aria-hidden="true"></i>',
        Benefits_thirdHeading: "Improved Safety <br>and Compliance",
        Benefits_thirdDescription:
          "Monitor vehicle compliance with safety regulations and industry standards, reducing risks associated with non-compliance.",
        Benefits_4thImg: '<i class="fa fa-shield" aria-hidden="true"></i>',
        Benefits_4thHeading: "Performance <br>Optimisation",
        Benefits_4thDescription:
          "Utilise data to improve route efficiency and driving Behaviours, leading to better fuel economy and reduced wear and tear.",
        // Use Cases12
        UseCases_firstImg: RequestADemo,
        UseCases_firstHeading: "Logistics and Distribution",
        UseCases_firstDescription:
          "Monitor vehicle performance to ensure timely deliveries and optimal resource allocation.",
        UseCases_secondImg: RequestADemo,
        UseCases_secondHeading: "Corporate Fleets",
        UseCases_secondDescription:
          "Keep track of vehicle health and compliance to maintain a safe and efficient fleet.",
        UseCases_thirdImg: RequestADemo,
        UseCases_thirdHeading: "Public Transport Services",
        UseCases_thirdDescription:
          "Assess the performance and compliance of public transport vehicles to enhance service quality and reliability.",
        // GettingStarted12
        Getting_StartedMainHeading:"",
        Getting_firstImg: RequestADemo,
        Getting_firstHeading: "Request a Demo",
        Getting_firstDescription:
          "Experience our comprehensive vehicle reporting tools with a Customised demonstration.",
        Getting_secondImg: RequestADemo,
        Getting_secondHeading: "Set Up Reporting Templates",
        Getting_secondDescription:
          "Collaborate with our team to establish reporting templates tailored to your fleet’s needs.",
        Getting_thirdImg: RequestADemo,
        Getting_thirdHeading: "Training and Support",
        Getting_thirdDescription:
          "Access ongoing training and support to maximize your use of vehicle reports and insights.",
      },
      // Advanced Reporting Pgae 13
      {
        heading: "Advanced Reporting",
        route_url: "advanced-reporting",
        Main_title:
          "Advanced <br/> <span style='color: #7ebcb1;'>Reporting</span>",
        Main_Description:
          "<strong>Advanced Reporting </strong> solutions from TecHGV provide in-depth analytics and Customised insights that enhance fleet management efficiency. Our robust reporting tools help fleet managers make data-driven decisions to Optimise operations, improve safety, and reduce costs.",
        Main_img: PhoneImg,
        // Key Features1
        KeyFeatures_firstImg: DriverChecksimg,
        KeyFeatures_firstHeading: "Customised Dashboards",
        KeyFeatures_firstDescription:
          "Create tailored dashboards that display key performance indicators (KPIs) relevant to your fleet’s specific goals.",
        KeyFeatures_secondImg: TorqueRegisterimg,
        KeyFeatures_secondHeading: "In-Depth Data Analytics",
        KeyFeatures_secondDescription:
          "Leverage advanced analytics to uncover trends, patterns, and correlations in your fleet’s performance data.",
        KeyFeatures_thirdImg: FuelAdblueUsageimg,
        KeyFeatures_thirdHeading: "Interactive Reporting Tools",
        KeyFeatures_thirdDescription:
          "Use drag-and-drop features to generate reports, allowing users to visualize data in meaningful ways.",
        // Benefits1
        Benefits_firstImg: '<i class="fa fa-shield" aria-hidden="true"></i>',
        Benefits_firstHeading: "Enhanced <br>Decision-Making",
        Benefits_firstDescription:
          "Utilise detailed analytics to support strategic decisions, from resource allocation to policy changes.",
        Benefits_secondImg: '<i class="fa fa-shield" aria-hidden="true"></i>',
        Benefits_secondHeading: "Proactive Risk <br>Management",
        Benefits_secondDescription:
          "Identify potential issues before they escalate by Analysing historical data and trends.",
        Benefits_thirdImg: '<i class="fa fa-shield" aria-hidden="true"></i>',
        Benefits_thirdHeading: "Operational <br>Efficiency",
        Benefits_thirdDescription:
          " Streamline operations by pinpointing inefficiencies and areas of improvement through data analysis.",
        Benefits_4thImg: '<i class="fa fa-shield" aria-hidden="true"></i>',
        Benefits_4thHeading: "Compliance <br>Assurance",
        Benefits_4thDescription:
          "Ensure adherence to industry regulations and standards through comprehensive tracking and reporting.",
        // Use Cases1
        UseCases_firstImg: RequestADemo,
        UseCases_firstHeading: "Logistics Providers",
        UseCases_firstDescription:
          "Optimise delivery routes and schedules using advanced performance analytics to improve operational efficiency.",
        UseCases_secondImg: RequestADemo,
        UseCases_secondHeading: "Corporate Fleets",
        UseCases_secondDescription:
          "Analyze driver Behaviour and vehicle usage to enhance safety protocols and reduce liabilities.",
        UseCases_thirdImg: RequestADemo,
        UseCases_thirdHeading: "Public Transport Authorities",
        UseCases_thirdDescription:
          "Utilise predictive maintenance reports to ensure service reliability and compliance with transport regulations.",
        // GettingStarted1
      Getting_StartedMainHeading:"",
        Getting_firstImg: RequestADemo,
        Getting_firstHeading: "Schedule a Consultation",
        Getting_firstDescription:
          "Discuss your advanced reporting needs with our experts to identify the right solutions.",
        Getting_secondImg: RequestADemo,
        Getting_secondHeading: "Customise  Your Dashboard",
        Getting_secondDescription:
          "Work with our team to design a reporting dashboard tailored to your specific key performance indicators.",
        Getting_thirdImg: RequestADemo,
        Getting_thirdHeading: "Ongoing Training and Support",
        Getting_thirdDescription:
          "Access resources and training to maximize the value of your advanced reporting tools.",
      },
      // T-Drive Page 14
      {
        heading: "T-Drive",
        route_url: "t-drive",
        Main_title:
          "T-Drive<br/> <span style='color: #7ebcb1;'> </span>",
        Main_Description:
          "<strong>T-Drive</strong> is TecHGV's secure and scalable cloud storage solution designed for customers to store, manage, and access all their reports and data effortlessly. With T-Drive, users can ensure their critical information is always safe, accessible, and organized.",
        Main_img: PhoneImg,
        // Key Features1
        KeyFeatures_firstImg: DriverChecksimg,
        KeyFeatures_firstHeading: "Secure Cloud Storage",
        KeyFeatures_firstDescription:
          "Protect your data with advanced encryption and security protocols, ensuring confidentiality and compliance.",
        KeyFeatures_secondImg: TorqueRegisterimg,
        KeyFeatures_secondHeading: "Easy Access",
        KeyFeatures_secondDescription:
          "Access your reports and data from anywhere, at any time, on any device, enabling seamless operations for your fleet management.",
        KeyFeatures_thirdImg: FuelAdblueUsageimg,
        KeyFeatures_thirdHeading: "Automated Backups",
        KeyFeatures_thirdDescription:
          "Benefit from automatic data backups to prevent loss and ensure continuity in case of unexpected incidents.",
        // Benefits1
        Benefits_firstImg: '<i class="fa fa-shield" aria-hidden="true"></i>',
        Benefits_firstHeading: "Centralized Data <br>Management",
        Benefits_firstDescription:
          "Keep all your reports and data in one place, making it easy to find and manage information.",
        Benefits_secondImg: '<i class="fa fa-shield" aria-hidden="true"></i>',
        Benefits_secondHeading: "Enhanced Data <br>Security",
        Benefits_secondDescription:
          "Protect sensitive fleet information with robust security measures, reducing the risk of data breaches.",
        Benefits_thirdImg: '<i class="fa fa-shield" aria-hidden="true"></i>',
        Benefits_thirdHeading: "Cost <br>Effective",
        Benefits_thirdDescription:
          "Reduce the need for physical storage and maintenance costs by utilizing cloud technology.",
        Benefits_4thImg: '<i class="fa fa-shield" aria-hidden="true"></i>',
        Benefits_4thHeading: "Scalability<br>",
        Benefits_4thDescription:
          "Easily scale your storage needs as your business grows without the hassle of upgrading physical systems.",
        // Use Cases1
        UseCases_firstImg: RequestADemo,
        UseCases_firstHeading: "Fleet Management",
        UseCases_firstDescription:
          "Store and organize vehicle reports, maintenance records, and compliance documentation securely in the cloud.",
        UseCases_secondImg: RequestADemo,
        UseCases_secondHeading: "Logistics Companies",
        UseCases_secondDescription:
          "Keep track of delivery data, shipment reports, and performance metrics for easy access and analysis.",
        UseCases_thirdImg: RequestADemo,
        UseCases_thirdHeading: "Corporate Fleets",
        UseCases_thirdDescription:
          "Archive important driver and vehicle data to ensure compliance and facilitate audits.",
        // GettingStarted1
      Getting_StartedMainHeading:"",
        Getting_firstImg: RequestADemo,
        Getting_firstHeading: "Create an Account",
        Getting_firstDescription:
          "Sign up for T-Drive and choose a storage plan that matches your needs.",
        Getting_secondImg: RequestADemo,
        Getting_secondHeading: "Upload Your Data",
        Getting_secondDescription:
          "Start uploading reports and important files quickly and easily.",
        Getting_thirdImg: RequestADemo,
        Getting_thirdHeading: "Utilise Training Resources",
        Getting_thirdDescription:
          "Access tutorials and support materials to maximize your T-Drive experience.",
      },
      // Document Centre(Vehicles, Drivers,Trailers) Page 15
      {
        heading: "Document Centre(Vehicles, Drivers, Trailers)",
        route_url: "document-centre",
        Main_title:
          "Document Centre<br/> <span style='color: #7ebcb1;'>(Vehicles, Drivers,Trailers)</span>",
        Main_Description:
          "<strong>Document Centre </strong>is TecHGV's centralised platform designed to manage, store, and access essential documents related to fleet management. This feature provides dedicated sections for Vehicles, Drivers, and Trailers, ensuring organized and efficient documentation.",
        Main_img: PhoneImg,
        // Key Features15
        KeyFeatures_firstImg: DriverChecksimg,
        KeyFeatures_firstHeading: "Centralized Document Storage",
        KeyFeatures_firstDescription:
          "Manage critical documents for Vehicles, Drivers, and Trailers in one secure location.",
        KeyFeatures_secondImg: TorqueRegisterimg,
        KeyFeatures_secondHeading: "Easy Document Retrieval",
        KeyFeatures_secondDescription:
          "Use advanced search functions to quickly locate documents by keywords, tags, or categories.",
        KeyFeatures_thirdImg: FuelAdblueUsageimg,
        KeyFeatures_thirdHeading: "Version Control",
        KeyFeatures_thirdDescription:
          "Track changes and maintain multiple versions of documents to ensure access to the latest information.",
        // Benefits15
        Benefits_firstImg: '<i class="fa fa-shield" aria-hidden="true"></i>',
        Benefits_firstHeading: "Streamlined <br>Operations",
        Benefits_firstDescription:
          "Simplify documentation processes, reducing administrative overhead and saving time.",
        Benefits_secondImg: '<i class="fa fa-shield" aria-hidden="true"></i>',
        Benefits_secondHeading: "Enhanced <br>Security",
        Benefits_secondDescription:
          "Protect sensitive documents with secure access controls and encryption, ensuring confidentiality.",
        Benefits_thirdImg: '<i class="fa fa-shield" aria-hidden="true"></i>',
        Benefits_thirdHeading: "Improved <br>Compliance",
        Benefits_thirdDescription:
          "Effortlessly maintain and access regulatory documents pertinent to each category for audits and compliance checks.",
        Benefits_4thImg: '<i class="fa fa-shield" aria-hidden="true"></i>',
        Benefits_4thHeading: "Increased <br>Productivity",
        Benefits_4thDescription:
          "Empower teams by minimizing time spent searching for documents and enabling quick access to critical information.",
        // Use Cases15
        UseCases_firstImg: RequestADemo,
        UseCases_firstHeading: "Vehicles",
        UseCases_firstDescription:
          "Store necessary documentation like registration and insurance for easy management and compliance.",
        UseCases_secondImg: RequestADemo,
        UseCases_secondHeading: "Drivers",
        UseCases_secondDescription:
          "Archive driver-specific documents to ensure each driver meets regulatory and company standards.",
        UseCases_thirdImg: RequestADemo,
        UseCases_thirdHeading: "Trailers",
        UseCases_thirdDescription:
          "Keep track of trailer inspections and maintenance history for safety and operational efficiency.",
        // GettingStarted15
      Getting_StartedMainHeading:"",
        Getting_firstImg: RequestADemo,
        Getting_firstHeading: "Create an Account",
        Getting_firstDescription:
          "Sign up for the Document Centre and explore its features.",
        Getting_secondImg: RequestADemo,
        Getting_secondHeading: "Upload Your Documents",
        Getting_secondDescription:
          "Begin adding important files specific to Vehicles, Drivers, and Trailers.",
        Getting_thirdImg: RequestADemo,
        Getting_thirdHeading: "Utilise Training Resources",
        Getting_thirdDescription:
          "Access tutorials and support materials to maximize your Document Centre experience.",
      },
      // Driver & Transport Manager App Page 16
      {
        heading: "Driver & Transport Manager App",
        route_url: "driver-and-transport-manager-app",
        Main_title:
          "Driver & Transport <br/> <span style='color: #7ebcb1;'>Manager App</span>",
        Main_Description:
          "The <strong>Driver & Transport Manager App</strong> from TecHGV is a powerful mobile solution designed to enhance fleet management efficiency. This app empowers drivers and transport managers with real-time tools to track performance, manage routes, and streamline communication.",
        Main_img: PhoneImg,
        // Key Features16
        KeyFeatures_firstImg: DriverChecksimg,
        KeyFeatures_firstHeading: "Real-Time Tracking",
        KeyFeatures_firstDescription:
          "Monitor vehicle locations in real-time, allowing for precise route management and timely updates.",
        KeyFeatures_secondImg: TorqueRegisterimg,
        KeyFeatures_secondHeading: "Driver Performance Monitoring",
        KeyFeatures_secondDescription:
          "Access metrics on driving Behaviour, such as speed, braking, and fuel efficiency, to Optimise performance.",
        KeyFeatures_thirdImg: FuelAdblueUsageimg,
        KeyFeatures_thirdHeading: "Route Optimisation",
        KeyFeatures_thirdDescription:
          "Generate optimal routes based on live traffic data and historical patterns, reducing travel time and costs.",
        // Benefits16
        Benefits_firstImg: '<i class="fa fa-shield" aria-hidden="true"></i>',
        Benefits_firstHeading: "Improved <br>Efficienc",
        Benefits_firstDescription:
          "Streamline operations with real-time updates, optimizing both routes and driver performance.",
        Benefits_secondImg: '<i class="fa fa-shield" aria-hidden="true"></i>',
        Benefits_secondHeading: "Enhanced <br>Safety",
        Benefits_secondDescription:
          "Monitor driving habits to promote safer driving practices and reduce the risk of accidents.",
        Benefits_thirdImg: '<i class="fa fa-shield" aria-hidden="true"></i>',
        Benefits_thirdHeading: "Cost <br>Savings",
        Benefits_thirdDescription:
          "Decrease fuel consumption and maintenance costs by optimising routes and monitoring vehicle performance.",
        Benefits_4thImg: '<i class="fa fa-shield" aria-hidden="true"></i>',
        Benefits_4thHeading: "Better <br>Accountability",
        Benefits_4thDescription:
          "Empower transport managers with tools to monitor drivers effectively and ensure compliance with company policies.",
        // Use Cases16
        UseCases_firstImg: RequestADemo,
        UseCases_firstHeading: "Logistics Companies",
        UseCases_firstDescription:
          "Enhance delivery efficiencies and reduce operational costs by utilizing real-time tracking and route Optimisation.",
        UseCases_secondImg: RequestADemo,
        UseCases_secondHeading: "Corporate Fleets",
        UseCases_secondDescription:
          "Monitor driver performance and ensure compliance with safety regulations across the fleet.",
        UseCases_thirdImg: RequestADemo,
        UseCases_thirdHeading: "Transport Services",
        UseCases_thirdDescription:
          "Streamline communication and incident reporting to improve service quality and response times.",
        // GettingStarted16
        Getting_StartedMainHeading:"",
        Getting_firstImg: RequestADemo,
        Getting_firstHeading: "Download the App",
        Getting_firstDescription:
          "Available on both iOS and Android platforms.",
        Getting_secondImg: RequestADemo,
        Getting_secondHeading: "Set Up Your Profile",
        Getting_secondDescription:
          "Create profiles for drivers and transport managers to access tailored features.",
        Getting_thirdImg: RequestADemo,
        Getting_thirdHeading: "Training and Support",
        Getting_thirdDescription:
          "Access resources and support to ensure your team maximizes the app’s capabilities.",
      },

      
    ],
  },
  {
    link_title: "Industries",
    route: "/industries",
    heading: "Industries",
    // feauters: ["AI Dashcam", "AI Dashcam", "AI Dashcam", "AI Dashcam"],
    multiRoutes: [
      // Trucking Page 1
      {
        heading: "Trucking",
        route_url: "trucking",
        // Main Section
        Main_Heading:"Trucking",
        Main_title:
          "Driving Efficiency and Compliance in the <br/> <span style='color: #7ebcb1;'>Transport and Logistics Industry</span>",
        Main_Description:
          "<strong></strong>As the backbone of the transport and logistics industry, trucking plays a crucial role in the movement of goods across the country. TecHGV, a leading provider of technology solutions, offers a comprehensive suite of tools and services to help trucking companies optimise their operations and stay ahead of the competition.",
        Main_img: HerosectionING,
        // Key Challenges Section
        Key_Challenges_Main_Heading:"Key Challenges in Trucking",
        Key_Challenges_Main_Description:"The trucking industry grapples with fleet management, regulatory compliance, operational efficiency, and driver retention in a competitive landscape.",
        Key_Features_Img:WantToSee,
        Key_Features_firstHeading:"Fleet Management",
        Key_Features_firstDescription:"Efficiently managing a fleet of trucks, drivers, and trailers.",
        Key_Features_secondHeading:"Regulatory Compliance",
        Key_Features_secondDescription:"Adhering to various transportation laws and safety regulations.",
        Key_Features_thirdeading:"Operational Efficiency",
        Key_Features_thirdDescription:"Improving productivity, reducing costs, and enhancing customer satisfaction.",
        Key_Features_fourthHeading:"Driver Retention",
        Key_Features_fourthDescription:"Attracting and retaining skilled drivers in a competitive market.",
        // Solutions Section
        Solutions_Main_Heading:"TecHGV's Trucking Solutions",
        Solutions_Main_Description:"TecHGV provides solutions that streamline fleet management, ensure compliance, and optimise operations for enhanced efficiency in trucking.",
        Solutions_Img:WantToSee,
        Solutions_firstHeading:"Fleet Management Software",
        Solutions_firstDescription:"<ul><li>Centralised platform to manage vehicle maintenance, fuel tracking, and asset Utilisation.</li><li>Real-time visibility into fleet operations and driver performance.</li><li>Streamlined dispatch and routing capabilities.</li></ul>",
        Solutions_secondHeading:"Compliance Monitoring",
        Solutions_secondDescription:"<ul><li>Comprehensive document management system for licenses, certifications, and regulatory records.</li><li>Automated alerts for upcoming renewals and compliance deadlines.</li><li>Seamless integration with electronic logging devices (ELDs) for hours-of-service tracking.</li></ul>",
        Solutions_thirdeading:"Optimisation Tools",
        Solutions_thirdDescription:"<ul><li>Advanced analytics and reporting for data-driven decision-making.</li><li>Route planning and Optimisation to reduce mileage and fuel consumption.</li><li>Load matching and backhaul management to maximise asset Utilisation.</li></ul>",
        Solutions_fourthHeading:"Driver Engagement Solutions",
        Solutions_fourthDescription:"<ul><li>Mobile apps for driver communication, incident reporting, and performance tracking.</li><li>Gamification and incentive programs to boost driver satisfaction and retention.</li><li>Training and development resources to upskill the workforce.</li></ul>",
        // Benefits Section
        Benefits_Main_Heading:"Benefits for Trucking Companies",
        Benefits_Main_Description:"Trucking companies benefit from improved efficiency, reduced costs, enhanced compliance, increased customer satisfaction, and a more engaged workforce.",
        Benefits_Img:BenefitsImg,
        Benefits_firstHeading:"Enhanced Efficiency",
        Benefits_firstDescription:"Streamlined operations, improved productivity, and reduced overall costs.",
        Benefits_secondHeading:"Regulatory Compliance",
        Benefits_secondDescription:"Seamless adherence to transportation laws and regulations.",
        Benefits_thirdeading:"Competitive Advantage",
        Benefits_thirdDescription:"Improved customer service, faster response times, and data-driven decision-making.",
        Benefits_fourthHeading:"Empowered Workforce",
        Benefits_fourthDescription:"Engaged drivers, reduced turnover, and a more skilled and productive team.",
      },
      // Logistics Page 2
      {
        heading: "Logistics",
        route_url: "logistics",
        Main_Heading:"Logistics",
        Main_title:
          "Powering Seamless <br/> <span style='color: #7ebcb1;'>Supply Chain Operations</span>",
        Main_Description:
          "<strong></strong>Logistics is the backbone of the global supply chain, encompassing the planning, implementation, and control of the efficient and effective flow of goods, services, and information. As a leading technology solutions provider, TecHGV offers a suite of innovative tools and services to help logistics companies streamline their operations and stay ahead of the competition.",
        Main_img: HerosectionING,
        // Key Challenges Section
        Key_Challenges_Main_Heading:"Key Challenges in Logistics",
        Key_Challenges_Main_Description:"Logistics faces challenges like supply chain disruptions, inventory management inefficiencies, and rising transportation costs.",
        Key_Features_Img:WantToSee,
        Key_Features_firstHeading:"Visibility and Transparency",
        Key_Features_firstDescription:"The supply chain lacks real-time data and end-to-end visibility.",
        Key_Features_secondHeading:"Inventory Management",
        Key_Features_secondDescription:"Optimising inventory levels to balance customer demand and operational costs.",
        Key_Features_thirdeading:"Operational Efficiency",
        Key_Features_thirdDescription:"Improving productivity, reducing costs, and enhancing customer satisfaction.",
        Key_Features_fourthHeading:"Regulatory Compliance",
        Key_Features_fourthDescription:" Adhering to various industry regulations and reporting requirements.",
        // Solutions Section
        Solutions_Main_Heading:"TecHGV's Logistics Solutions",
        Solutions_Main_Description:"TecHGV offers logistics solutions that optimise supply chain efficiency and enhance inventory management for streamlined operations.",
        Solutions_Img:WantToSee,
        Solutions_firstHeading:"Supply Chain Visibility",
        Solutions_firstDescription:"<ul><li>Integrated platform for real-time tracking and monitoring of shipments and assets.</li><li>Predictive analytics to identify potential disruptions and optimise decision-making.</li><li>Comprehensive reporting and dashboards for end-to-end supply chain visibility.</li></ul>",
        Solutions_secondHeading:"Warehouse Management System (WMS)",
        Solutions_secondDescription:"<ul><li>Centralised control of inventory, storage, and order fulfilment processes.</li><li>Automated task scheduling and resource optimisation to boost productivity.</li><li>Seamless integration with existing ERP and transportation management systems.</li></ul>",
        Solutions_thirdeading:"Optimisation Tools",
        Solutions_thirdDescription:"<ul><li>Advanced analytics and AI-powered algorithms for route planning and load Optimisation.</li><li>Predictive modelling for demand forecasting and inventory management.</li><li>Automated tender management and carrier selection to reduce transportation costs.</li></ul>",
        Solutions_fourthHeading:"Compliance and Reporting",
        Solutions_fourthDescription:"<ul><li>Comprehensive document management system for licenses, certifications, and regulatory records.</li><li>Automated alerts and notifications for compliance deadlines and reporting requirements.</li><li>Customisable dashboards and analytics to monitor key performance indicators.</li></ul>",
        // Benefits Section
        Benefits_Main_Heading:"Benefits for Logistics Companies",
        Benefits_Main_Description:"Logistics companies gain greater supply chain visibility, optimised inventory management, reduced costs, improved customer service, and heightened operational efficiency.",
        Benefits_Img:BenefitsImg,
        Benefits_firstHeading:"Enhanced Visibility",
        Benefits_firstDescription:"Real-time data and end-to-end supply chain transparency.",
        Benefits_secondHeading:"Improved Efficiency",
        Benefits_secondDescription:"Streamlined operations, reduced costs, and enhanced customer satisfaction.",
        Benefits_thirdeading:"Competitive Advantage",
        Benefits_thirdDescription:"Faster response times, data-driven decision-making, and improved service levels.",
        Benefits_fourthHeading:"Regulatory Compliance",
        Benefits_fourthDescription:"Seamless adherence to industry regulations and reporting requirements.",
      },
      // Distribution Page 3
      {
        heading: "Distribution",
        route_url: "distribution",
        Main_Heading:"Distribution",
        Main_title:
          "Transforming the Last<br/> <span style='color: #7ebcb1;'>Mile with Innovative Solutions</span>",
        Main_Description:
          "In today's fast-paced and competitive landscape, the distribution sector plays a crucial role in delivering goods and services to customers efficiently and cost-effectively. TecHGV, a leading technology solutions provider, offers a suite of innovative tools and services to help distribution companies streamline their operations, enhance customer experience, and stay ahead of the competition.",
        Main_img: HerosectionING,
        // Key Challenges Section
        Key_Challenges_Main_Heading:"Key Challenges in Distribution",
        Key_Challenges_Main_Description:"Distribution faces challenges such as managing inventory levels, ensuring timely deliveries, navigating regulatory compliance, and optimising transportation costs.",
        Key_Features_Img:WantToSee,
        Key_Features_firstHeading:"Route Optimisation",
        Key_Features_firstDescription:"Designing efficient delivery routes to minimise costs and enhance customer satisfaction.",
        Key_Features_secondHeading:"Inventory Management",
        Key_Features_secondDescription:"Maintaining optimal inventory levels to meet customer demand while minimising holding costs.",
        Key_Features_thirdeading:"Real-time Visibility",
        Key_Features_thirdDescription:"Lack of end-to-end visibility and transparency across the distribution network.",
        Key_Features_fourthHeading:"Last-mile Delivery",
        Key_Features_fourthDescription:"Addressing the complexities and costs associated with the final leg of the delivery process.",
        // Solutions Section
        Solutions_Main_Heading:"TecHGV's Distribution Solutions",
        Solutions_Main_Description:"TecHGV offers distribution solutions that streamline inventory management, enhance order fulfilment, and optimise transportation logistics for efficient delivery.",
        Solutions_Img:WantToSee,
        Solutions_firstHeading:"Route Optimisation and Scheduling",
        Solutions_firstDescription:"<ul><li>Advanced algorithms for dynamic route planning and scheduling.</li><li>Real-time updates and rerouting to adapt to changing conditions.</li><li>Seamless integration with fleet management and dispatch systems.</li></ul>",
        Solutions_secondHeading:"Warehouse and Inventory Management",
        Solutions_secondDescription:"<ul><li>Centralised control of inventory, storage, and order fulfilment processes.</li><li>Predictive analytics for demand forecasting and inventory Optimisation.</li><li>Automated replenishment and cross-docking capabilities.</li></ul>",
        Solutions_thirdeading:"Distribution Visibility and Tracking",
        Solutions_thirdDescription:"<ul><li>Integrated platform for real-time monitoring of shipments and assets.</li><li>Comprehensive reporting and analytics for end-to-end visibility.</li><li>Customisable dashboards and alerts for proactive decision-making.</li></ul>",
        Solutions_fourthHeading:"Last-mile Delivery Optimisation",
        Solutions_fourthDescription:"<ul><li>AI-powered algorithms for efficient last-mile route planning and delivery scheduling.</li><li>Integration with third-party delivery service providers and crowdsourcing platforms.</li><li>Customer-centric features like real-time tracking, notifications, and self-service options.</li></ul>",
        // Benefits Section
        Benefits_Main_Heading:"Benefits for Distribution Companies",
        Benefits_Main_Description:"Distribution companies benefit from streamlined inventory management, faster order fulfilment, improved delivery accuracy, and reduced operational costs.",
        Benefits_Img:BenefitsImg,
        Benefits_firstHeading:"Enhanced Efficiency",
        Benefits_firstDescription:"Streamlined operations, reduced costs, and improved delivery performance.",
        Benefits_secondHeading:"Improved Visibility",
        Benefits_secondDescription:"Real-time data and end-to-end distribution network transparency.",
        Benefits_thirdeading:"Enhanced Customer Experience",
        Benefits_thirdDescription:"Faster delivery times, proactive communication, and personalised services.",
        Benefits_fourthHeading:"Competitive Advantage",
        Benefits_fourthDescription:"Data-driven decision-making, improved service levels, and optimised resource Utilisation.",
      },
      // Water Transportation Page 4
      {
        heading: "Water Transportation",
        route_url: "water-transportation",
        Main_Heading:"Water Transportation",
        Main_title:
          "Navigating the Seas with<br/> <span style='color: #7ebcb1;'>Seamless Technology Solutions</span>",
        Main_Description:
          "Water transportation plays a pivotal role in the global supply chain, enabling the movement of goods and commodities across oceans and waterways. As a leading technology solutions provider, TecHGV offers a comprehensive suite of innovative tools and services to help maritime and inland waterway transportation companies streamline their operations, enhance efficiency, and stay ahead of the competition.",
        Main_img: HerosectionING,
        // Key Challenges Section
        Key_Challenges_Main_Heading:"Key Challenges in Water Transportation",
        Key_Challenges_Main_Description:"Water transportation faces challenges such as port congestion, regulatory compliance, fluctuating fuel costs, and the need for efficient cargo handling and scheduling.",
        Key_Features_Img:WantToSee,
        Key_Features_firstHeading:"Fleet Management",
        Key_Features_firstDescription:"Optimising the Utilisation and performance of ships, barges, and other vessels.",
        Key_Features_secondHeading:"Regulatory Compliance",
        Key_Features_secondDescription:"Adhering to complex maritime regulations and reporting requirements.",
        Key_Features_thirdeading:"Operational Efficiency",
        Key_Features_thirdDescription:"Improving productivity, reducing costs, and enhancing customer satisfaction.",
        Key_Features_fourthHeading:"Real-time Visibility",
        Key_Features_fourthDescription:"Lack of end-to-end visibility and transparency across the water transportation network.",
        // Solutions Section
        Solutions_Main_Heading:"TecHGV's Water Transportation Solutions",
        Solutions_Main_Description:"TecHGV provides water transportation solutions that enhance fleet management, improve cargo tracking, and streamline compliance to optimise operational efficiency.",
        Solutions_Img:WantToSee,
        Solutions_firstHeading:"Fleet Management and Optimisation",
        Solutions_firstDescription:"<ul><li>Real-time tracking and monitoring of vessels, cargo, and equipment.</li><li>Predictive analytics for maintenance scheduling and fuel Optimisation.</li><li>Automated scheduling and routing to improve asset Utilisation.</li></ul>",
        Solutions_secondHeading:"Regulatory Compliance and Reporting",
        Solutions_secondDescription:"<ul><li>Comprehensive document management system for licenses, certifications, and regulatory records.</li><li>Automated alerts and notifications for compliance deadlines and reporting requirements.</li><li>Customizable dashboards and analytics to monitor key performance indicators.</li></ul>",
        Solutions_thirdeading:"Operational Efficiency and Productivity",
        Solutions_thirdDescription:"<ul><li>Integrated platform for streamlining port operations and cargo handling.</li><li>Automated task scheduling and resource optimisation to boost productivity.</li><li>Seamless integration with existing ERP and transportation management systems.</li></ul>",
        Solutions_fourthHeading:"Supply Chain Visibility and Transparency",
        Solutions_fourthDescription:"<ul><li>Comprehensive tracking and monitoring of shipments and assets across the water transportation network.</li><li>Predictive analytics to identify potential disruptions and optimise decision-making.</li><li>Real-time data and end-to-end visibility for improved planning and decision-making.</li></ul>",
        // Benefits Section
        Benefits_Main_Heading:"Benefits for Water Transportation Companies",
        Benefits_Main_Description:"Water transportation companies enjoy optimised fleet management, improved cargo tracking, enhanced compliance, and increased operational efficiency.",
        Benefits_Img:BenefitsImg,
        Benefits_firstHeading:"Enhanced Efficiency",
        Benefits_firstDescription:"Streamlined operations, reduced costs, and improved service levels.",
        Benefits_secondHeading:"Improved Visibility",
        Benefits_secondDescription:"Real-time data and end-to-end supply chain transparency.",
        Benefits_thirdeading:"Regulatory Compliance",
        Benefits_thirdDescription:"Seamless adherence to complex maritime regulations and reporting requirements.",
        Benefits_fourthHeading:"Competitive Advantage",
        Benefits_fourthDescription:"Faster response times, data-driven decision-making, and improved customer satisfaction.",
      },
      // Milk Transportation Page 5
      {
        heading: "Milk Transportation",
        route_url: "milk-transportation",
        Main_Heading:"Milk Transportation",
        Main_title:
          "Ensuring the Safe and Efficient <br/> <span style='color: #7ebcb1;'>Movement of Dairy Products</span>",
        Main_Description:
          "The transportation of milk and dairy products is a critical component of the food supply chain, requiring specialised expertise and tailored solutions. TecHGV, a leading technology solutions provider, offers a comprehensive suite of tools and services to help milk transportation companies optimise their operations, maintain product quality, and deliver superior customer service.",
        Main_img: HerosectionING,
        // Key Challenges Section
        Key_Challenges_Main_Heading:"Key Challenges in Milk Transportation",
        Key_Challenges_Main_Description:"Milk transportation faces challenges such as maintaining temperature control, ensuring timely deliveries, managing regulatory compliance, and minimizing spoilage during transit.",
        Key_Features_Img:WantToSee,
        Key_Features_firstHeading:"Temperature and Humidity Control",
        Key_Features_firstDescription:"Ensuring the proper temperature and humidity levels throughout the transportation process to maintain product freshness and safety.",
        Key_Features_secondHeading:"Route Optimisation",
        Key_Features_secondDescription:"Designing efficient delivery routes to minimise costs and maximise on-time deliveries.",
        Key_Features_thirdeading:"Regulatory Compliance",
        Key_Features_thirdDescription:"Adhering to strict food safety regulations and reporting requirements.",
        Key_Features_fourthHeading:"Supply Chain Visibility",
        Key_Features_fourthDescription:"Lack of real-time visibility and tracking across the milk transportation network.",
        // Solutions Section
        Solutions_Main_Heading:"TecHGV's Milk Transportation Solutions",
        Solutions_Main_Description:"TecHGV offers milk transportation solutions that optimise temperature monitoring, enhance route planning, and ensure compliance to maintain product quality and efficiency.",
        Solutions_Img:WantToSee,
        Solutions_firstHeading:"Cold Chain Management",
        Solutions_firstDescription:"<ul><li>Intelligent temperature and humidity monitoring systems for refrigerated trucks and trailers.</li><li>Predictive analytics for proactive maintenance and equipment Optimisation.</li><li>Automated alerts and notifications for temperature deviations or other issues.</li></ul>",
        Solutions_secondHeading:"Route Optimisation and Scheduling",
        Solutions_secondDescription:"<ul><li>Advanced algorithms for dynamic route planning and delivery scheduling.</li><li>Real-time updates and rerouting to adapt to changing conditions and priority deliveries.</li><li>Seamless integration with fleet management and dispatch systems.</li></ul>",
        Solutions_thirdeading:"Regulatory Compliance and Reporting",
        Solutions_thirdDescription:"<ul><li>Centralized platform for managing food safety documentation and certifications.</li><li>Automated reporting and analytics for regulatory compliance and traceability.</li><li>Customizable dashboards and alerts to monitor key performance indicators.</li></ul>",
        Solutions_fourthHeading:"Supply Chain Visibility and Tracking",
        Solutions_fourthDescription:"<ul><li>Integrated platform for real-time monitoring of milk shipments and assets.</li><li>Comprehensive reporting and analytics for end-to-end supply chain visibility.</li><li>Customizable alerts and notifications for proactive decision-making.</li></ul>",
        // Benefits Section
        Benefits_Main_Heading:"Benefits for Milk Transportation Companies",
        Benefits_Main_Description:"Milk transportation companies benefit from better temperature monitoring, reduced spoilage, improved delivery times, and enhanced route planning.",
        Benefits_Img:BenefitsImg,
        Benefits_firstHeading:"Improved Food Safety",
        Benefits_firstDescription:"Consistent temperature and humidity control to maintain product quality and safety.",
        Benefits_secondHeading:"Enhanced Efficiency",
        Benefits_secondDescription:"Streamlined operations, reduced costs, and improved delivery performance.",
        Benefits_thirdeading:"Regulatory Compliance",
        Benefits_thirdDescription:"Seamless adherence to food safety regulations and traceability requirements.",
        Benefits_fourthHeading:"Competitive Advantage",
        Benefits_fourthDescription:"Improved supply chain visibility, data-driven decision-making, and superior customer service.",
      },
      // Emergency Response Page 6 Done
      {
        heading: "Emergency Response",
        route_url: "emergency-response",
        Main_Heading:"Emergency Response",
        Main_title:
          "Empowering First Responders with <br/> <span style='color: #7ebcb1;'>Innovative Technology Solutions</span>",
        Main_Description:
          "In the face of critical situations, first responders rely on seamless communication, real-time data, and streamlined operations to provide timely and effective emergency services. As a trusted technology partner, TecHGV offers a range of cutting-edge solutions to help emergency services organisations enhance their emergency response capabilities and better serve their communities.",
        Main_img: HerosectionING,
        // Key Challenges Section
        Key_Challenges_Main_Heading:"Key Challenges in Emergency Response",
        Key_Challenges_Main_Description:"Emergency response faces challenges such as communication breakdowns, resource allocation, coordination among agencies, and the need for rapid decision-making in high-pressure situations.",
        Key_Features_Img:WantToSee,
        Key_Features_firstHeading:"Communication and Coordination",
        Key_Features_firstDescription:"Ensuring seamless communication and coordination among multiple agencies and first responders.",
        Key_Features_secondHeading:"Real-time Situational Awareness",
        Key_Features_secondDescription:"Lack of comprehensive and up-to-date information about the emergency situation.",
        Key_Features_thirdeading:"Resource Optimisation",
        Key_Features_thirdDescription:"Efficiently allocating and deploying limited resources to meet the demands of the emergency.",
        Key_Features_fourthHeading:"Post-incident Analysis",
        Key_Features_fourthDescription:"Gathering and analyzing data to improve future emergency response efforts.",
        // Solutions Section
        Solutions_Main_Heading:"TecHGV's Emergency Response Solutions",
        Solutions_Main_Description:"TecHGV offers solutions that optimise communication and resource management for rapid and effective emergency response.",
        Solutions_Img:WantToSee,
        Solutions_firstHeading:"Integrated Communication and Collaboration",
        Solutions_firstDescription:"<ul><li>Unified communication platform for voice, data, and video sharing across agencies and devices.</li><li>Secure and reliable mobile applications for real-time information exchange.</li><li>Interoperability solutions to bridge communication gaps between disparate systems.</li></ul>",
        Solutions_secondHeading:"Situational Awareness and Incident Management",
        Solutions_secondDescription:"<ul><li>Geospatial intelligence and mapping tools for enhanced situational awareness.</li><li>Predictive analytics and decision support systems to aid in crisis management.</li><li>Automated data aggregation and visualization for a comprehensive common operating picture.</li></ul>",
        Solutions_thirdeading:"Resource Management and Optimisation",
        Solutions_thirdDescription:"<ul><li>Dynamic resource allocation and dispatching algorithms for efficient response.</li><li>Fleet management and asset tracking solutions to monitor and optimize resource Utilisation.</li><li>Integrated logistics and supply chain management to ensure the availability of critical supplies.</li></ul>",
        Solutions_fourthHeading:"Post-incident Analysis and Continuous Improvement",
        Solutions_fourthDescription:"<ul><li>Comprehensive data collection and analytics platform for incident review and after-action reporting.</li><li>Predictive Modelling and simulations to identify areas for process improvement.</li><li>Knowledge management and collaboration tools to share best practices and lessons learned.</li></ul>",
        // Benefits Section
        Benefits_Main_Heading:"Benefits for Emergency Services Organisations",
        Benefits_Main_Description:"Emergency response services gain improved coordination, faster response times, better resource management, and enhanced communication during crises.",
        Benefits_Img:BenefitsImg,
        Benefits_firstHeading:"Improved Communication and Coordination",
        Benefits_firstDescription:"Enhanced interoperability and seamless information sharing among agencies.",
        Benefits_secondHeading:"Enhanced Situational Awareness",
        Benefits_secondDescription:" Comprehensive real-time data and a common operating picture for better decision-making.",
        Benefits_thirdeading:"Optimised Resource Utilisation",
        Benefits_thirdDescription:"Efficient allocation and deployment of personnel, equipment, and supplies.",
        Benefits_fourthHeading:"Continuous Improvement",
        Benefits_fourthDescription:" Data-driven analysis and insights to enhance future emergency response efforts.",
      },
      // Ambulance Services Page 7 Done
      {
        heading: "Ambulance Services",
        route_url: "ambulance-services",
        Main_Heading:"Ambulance Services",
        Main_title:
          "Leveraging Technology to Enhance<br/> <span style='color: #7ebcb1;'>Emergency Medical ResponseOverview</span>",
        Main_Description:
          "Ambulance services play a critical role in providing timely and effective emergency medical care to communities. As a leading technology solutions provider, TecHGV offers a comprehensive suite of tools and services to help ambulance service providers optimize their operations, improve patient outcomes, and deliver superior patient care.",
        Main_img: HerosectionING,
        // Key Challenges Section
        Key_Challenges_Main_Heading:"Key Challenges in Ambulance Services",
        Key_Challenges_Main_Description:"Ambulance services face challenges such as response time delays, communication inefficiencies, resource allocation, and maintaining equipment readiness in high-pressure situations.",
        Key_Features_Img:WantToSee,
        Key_Features_firstHeading:"Real-time Dispatch and Routing",
        Key_Features_firstDescription:"Efficiently dispatching and routing ambulances to emergencies.",
        Key_Features_secondHeading:"Patient Care and Monitoring",
        Key_Features_secondDescription:"Ensuring accurate and timely patient data capture and transmission.",
        Key_Features_thirdeading:"Fleet Management and Maintenance",
        Key_Features_thirdDescription:"Optimising the Utilisation and maintenance of ambulance fleets.",
        Key_Features_fourthHeading:"Regulatory Compliance and Reporting",
        Key_Features_fourthDescription:"Adhering to strict healthcare regulations and reporting requirements.",
        // Solutions Section
        Solutions_Main_Heading:"TecHGV's Ambulance Services Solutions",
        Solutions_Main_Description:"TecHGV provides ambulance service solutions that enhance dispatch efficiency, improve communication, and ensure resource readiness for timely emergency responses.",
        Solutions_Img:WantToSee,
        Solutions_firstHeading:"Intelligent Dispatch and Routing",
        Solutions_firstDescription:"<ul><li>Advanced algorithms for dynamic dispatch and route Optimisation.</li><li>Real-time traffic and incident monitoring for proactive rerouting.</li><li>Seamless integration with Computer-Aided Dispatch (CAD) systems.</li></ul>",
        Solutions_secondHeading:"Patient Care and Monitoring",
        Solutions_secondDescription:"<ul><li>Secure and reliable mobile platforms for electronic patient care reporting.</li><li>Telemedicine and remote patient monitoring capabilities.</li><li>Automated data integration with Electronic Health Record (EHR) systems.</li></ul>",
        Solutions_thirdeading:"Fleet Management and Maintenance",
        Solutions_thirdDescription:"<ul><li>GPS-enabled vehicle tracking and asset management.</li><li>Predictive maintenance and fleet Optimisation tools.</li><li>Automated reporting and analytics for improved decision-making.</li></ul>",
        Solutions_fourthHeading:"Regulatory Compliance and Reporting",
        Solutions_fourthDescription:"<ul><li>Centralized platform for managing medical documentation and certifications.</li><li>Automated reporting and analytics for regulatory compliance and quality assurance.</li><li>Customizable dashboards and alerts to monitor key performance indicators.</li></ul>",
        // Benefits Section
        Benefits_Main_Heading:"Benefits for Ambulance Service Providers",
        Benefits_Main_Description:"Ambulance services experience improved dispatch efficiency, enhanced communication, and better resource readiness for timely emergency responses.",
        Benefits_Img:BenefitsImg,
        Benefits_firstHeading:"Improved Response Times",
        Benefits_firstDescription:"Optimised dispatch and routing for faster emergency response.",
        Benefits_secondHeading:"Enhanced Patient Care",
        Benefits_secondDescription:" Accurate and timely patient data capture and transmission.",
        Benefits_thirdeading:"Increased Fleet Efficiency",
        Benefits_thirdDescription:"Optimised utilisation and proactive maintenance of ambulance fleets.",
        Benefits_fourthHeading:"Regulatory Compliance",
        Benefits_fourthDescription:"Seamless adherence to healthcare regulations and reporting requirements.",
        Benefits_fourthHeading:"Competitive Advantage",
        Benefits_fourthDescription:"Data-driven decision-making, improved operational efficiency, and superior patient outcomes.",
      },
      // Fire Services Page 8 Done
      {
        heading: "Fire Services",
        route_url: "fire-services",
        Main_Heading:"Fire Services",
        Main_title:
        "Empowering Firefighters with<br/> <span style='color: #7ebcb1;'>Innovative Technology Solutions</span>",
      Main_Description:
        "Firefighters and fire departments play a vital role in protecting communities from the devastating effects of fires and other emergencies. TechGV is committed to supporting fire service organisations with cutting-edge technology solutions that enhance their firefighting capabilities, improve safety, and enable more effective emergency response.",
      Main_img: HerosectionING,
      // Key Challenges Section
      Key_Challenges_Main_Heading:"Key Challenges in Fire Services",
      Key_Challenges_Main_Description:"Fire services face challenges such as maintaining quick response times, ensuring effective communication, managing equipment readiness, and coordinating with multiple agencies during emergencies.",
      Key_Features_Img:WantToSee,
      Key_Features_firstHeading:"Situational Awareness and Incident Command",
      Key_Features_firstDescription:"Lack of real-time information and poor visibility into emergency situations.",
      Key_Features_secondHeading:"Resource Deployment and Coordination",
      Key_Features_secondDescription:" Inefficient allocation and deployment of firefighting personnel and equipment.",
      Key_Features_thirdeading:"Equipment Maintenance and Tracking",
      Key_Features_thirdDescription:"Difficulty in managing and maintaining critical firefighting assets.",
      Key_Features_fourthHeading:"Data-driven Decision Making",
      Key_Features_fourthDescription:" Limited access to data and analytics to support strategic planning and continuous improvement.",
      // Solutions Section
      Solutions_Main_Heading:"TechGV's Fire Services Solutions",
      Solutions_Main_Description:"TecHGV offers fire services solutions that enhance incident management, improve communication, and streamline resource allocation for efficient emergency response.",
      Solutions_Img:WantToSee,
      Solutions_firstHeading:"Situational Awareness and Incident Command",
      Solutions_firstDescription:"<ul><li>Advanced sensors and IoT devices for real-time data collection.</li><li>Integrated command and control systems with data visualization and analysis.</li><li>Augmented reality and wearable technologies to enhance firefighter situational awareness.</li></ul>",
      Solutions_secondHeading:"Resource Deployment and Coordination",
      Solutions_secondDescription:"<ul><li>Intelligent dispatch and resource allocation algorithms.</li><li>Integrated communication and collaboration platforms for seamless coordination.</li><li>Automated personnel and equipment tracking and monitoring.</li></ul>",
      Solutions_thirdeading:"Asset Management and Maintenance",
      Solutions_thirdDescription:"<ul><li>RFID and GPS-enabled asset tracking and inventory management.</li><li>Predictive maintenance and equipment lifecycle management.</li><li>Automated data collection and reporting for regulatory compliance.</li></ul>",
      Solutions_fourthHeading:"Data-driven Decision Making",
      Solutions_fourthDescription:"<ul><li>Comprehensive data analytics and business intelligence platforms.</li><li>Predictive modelling and simulation tools for incident planning and response.</li><li>Knowledge management and collaboration tools for sharing best practices.</li></ul>",
      // Benefits Section
      Benefits_Main_Heading:"Benefits for Fire Service Organisations",
      Benefits_Main_Description:"Fire services benefit from improved incident management, enhanced coordination, and optimised resource allocation for effective emergency responses.",
      Benefits_Img:BenefitsImg,
      Benefits_firstHeading:"Enhanced Situational Awareness",
      Benefits_firstDescription:" Improved visibility and real-time information for more effective incident command.",
      Benefits_secondHeading:"Optimised Resource Deployment",
      Benefits_secondDescription:" Efficient allocation and coordination of firefighting personnel and equipment.",
      Benefits_thirdeading:"Improved Asset Management",
      Benefits_thirdDescription:"Streamlined maintenance, tracking, and compliance for critical firefighting assets.",
      Benefits_fourthHeading:"Data-driven Continuous Improvement",
      Benefits_fourthDescription:"Actionable insights and analytics to support strategic decision-making and ongoing Optimisation.",
      },
      // Mining Industry Page 9 Done All
      {
        heading: "Mining Industry",
        route_url: "mining-industry",
        Main_Heading:"Mining Industry",
        Main_title:
          "Transforming Operations with<br/> <span style='color: #7ebcb1;'>Cutting-Edge Technology</span>",
        Main_Description:
          "The mining industry plays a vital role in the global economy, providing essential raw materials for various industries. TechGV is committed to supporting mining companies with innovative technology solutions that enhance operational efficiency, improve safety, and drive sustainable practices.",
        Main_img: HerosectionING,
        // Key Challenges Section
        Key_Challenges_Main_Heading:"Key Challenges in the Mining Industry",
        Key_Challenges_Main_Description:"The mining industry faces challenges like safety hazards, regulatory compliance, fluctuating prices, and environmental impact management.",
        Key_Features_Img:WantToSee,
        Key_Features_firstHeading:"Operational Optimisation",
        Key_Features_firstDescription:"Inefficient mining processes and equipment Utilisation.",
        Key_Features_secondHeading:"Health, Safety, and Environment (HSE)",
        Key_Features_secondDescription:" Ensuring the well-being of workers and minimising environmental impact.",
        Key_Features_thirdeading:"Asset Management and Maintenance",
        Key_Features_thirdDescription:"Effective management and maintenance of mission-critical mining equipment.",
        Key_Features_fourthHeading:"Data-driven Decision Making",
        Key_Features_fourthDescription:"Limited access to real-time data and analytics to support strategic decision-making.",
        // Solutions Section
        Solutions_Main_Heading:"TechGV's Mining Industry Solutions",
        Solutions_Main_Description:"TecHGV provides mining industry solutions that enhance safety, optimise resource management, and improve compliance and operational efficiency.",
        Solutions_Img:WantToSee,
        Solutions_firstHeading:"Operational Optimisation",
        Solutions_firstDescription:"<ul><li>Autonomous and semi-autonomous mining equipment and vehicles.</li><li>Advanced process Optimisation algorithms for improved productivity.</li><li>Predictive maintenance and fleet management systems.</li></ul>",
        Solutions_secondHeading:"Health, Safety, and Environment (HSE)",
        Solutions_secondDescription:"<ul><li>Wearable technology and IoT sensors for worker safety monitoring.</li><li>Intelligent environmental monitoring and compliance systems.</li><li>Automated hazard detection and emergency response coordination.</li></ul>",
        Solutions_thirdeading:"Asset Management and Maintenance",
        Solutions_thirdDescription:"<ul><li>RFID and GPS-enabled asset tracking and maintenance planning.</li><li>Predictive maintenance models for optimised equipment uptime.</li><li>Automated reporting and compliance documentation.</li></ul>",
        Solutions_fourthHeading:" Data-driven Decision Making",
        Solutions_fourthDescription:"<ul><li>Integrated data analytics and business intelligence platforms.</li><li>Simulation and modelling tools for operational planning and Optimisation.</li><li>Collaboration and knowledge management systems for sharing best practices.</li></ul>",
        // Benefits Section
        Benefits_Main_Heading:"Benefits for Mining Companies",
        Benefits_Main_Description:"Mining companies gain enhanced safety, improved operational efficiency, better compliance with regulations, and optimised resource management.",
        Benefits_Img:BenefitsImg,
        Benefits_firstHeading:"Increased Productivity",
        Benefits_firstDescription:"Improved mining process efficiency and equipment Utilisation.",
        Benefits_secondHeading:"Enhanced Worker Safety",
        Benefits_secondDescription:"Proactive monitoring and intelligent hazard response.",
        Benefits_thirdeading:"Optimised Asset Management",
        Benefits_thirdDescription:"Streamlined maintenance and compliance for critical equipment.",
        Benefits_fourthHeading:"Informed Decision-making",
        Benefits_fourthDescription:"Actionable insights and data-driven strategic planning.",
      },
      // Oil & Gas Industry Page 10 Done
      {
        heading: "Oil & Gas Industry",
        route_url: "oil-and-gas-industry",
        Main_Heading:"Oil & Gas Industry",
        Main_title:
          " Empowering Operational <br/> <span style='color: #7ebcb1;'>Excellence with Innovative Technologies</span>",
        Main_Description:
          "The oil and gas industry plays a crucial role in meeting the world's energy demands. TechGV is dedicated to supporting oil and gas companies with advanced technology solutions that enhance operational efficiency, improve safety, and drive sustainability.",
        Main_img: HerosectionING,
        // Key Challenges Section
        Key_Challenges_Main_Heading:"Key Challenges in the Oil & Gas Industry",
        Key_Challenges_Main_Description:"The oil and gas industry faces challenges like fluctuating prices, regulatory compliance, environmental concerns, and supply chain disruptions.",
        Key_Features_Img:WantToSee,
        Key_Features_firstHeading:"Upstream Operations Optimisation",
        Key_Features_firstDescription:"Improving exploration, drilling, and production processes.",
        Key_Features_secondHeading:"Midstream and Downstream Optimisation",
        Key_Features_secondDescription:"Optimising transportation, storage, and distribution operations.",
        Key_Features_thirdeading:"Health, Safety, and Environment (HSE)",
        Key_Features_thirdDescription:"Ensuring the well-being of workers and minimising environmental impact.",
        Key_Features_fourthHeading:"Asset Management and Maintenance",
        Key_Features_fourthDescription:"Effective management and maintenance of critical oil and gas infrastructure.",
        // Solutions Section
        Solutions_Main_Heading:"TechGV's Oil & Gas Industry Solutions",
        Solutions_Main_Description:"TecHGV offers oil and gas industry solutions that enhance operational efficiency, ensure regulatory compliance, and optimise supply chain management for improved productivity.",
        Solutions_Img:WantToSee,
        Solutions_firstHeading:"Upstream Operations Optimisation",
        Solutions_firstDescription:"<ul><li>Predictive analytics and AI-powered drilling Optimisation.</li><li>Autonomous and remote-controlled drilling and production equipment.</li><li>Integrated reservoir management and production Optimisation.</li></ul>",
        Solutions_secondHeading:"Midstream and Downstream Optimisation",
        Solutions_secondDescription:"<ul><li>Intelligent pipeline monitoring and leak detection systems.</li><li>Automated terminal and storage tank management.</li><li>Optimised logistics and transportation planning.</li></ul>",
        Solutions_thirdeading:"Health, Safety, and Environment (HSE)",
        Solutions_thirdDescription:"<ul><li>Wearable technology and IoT sensors for worker safety monitoring.</li><li>Advanced environmental monitoring and emissions tracking.</li><li>Automated hazard detection and emergency response coordination.</li></ul>",
        Solutions_fourthHeading:"Asset Management and Maintenance",
        Solutions_fourthDescription:"<ul><li>RFID and GPS-enabled asset tracking and maintenance planning.</li><li>Predictive maintenance models for critical infrastructure.</li><li>Automated reporting and compliance documentation.</li></ul>",
        // Benefits Section
        Benefits_Main_Heading:"Benefits for Oil & Gas Companies",
        Benefits_Main_Description:"Oil and gas companies enjoy increased operational efficiency, enhanced regulatory compliance, reduced costs, and improved resource allocation.",
        Benefits_Img:BenefitsImg,
        Benefits_firstHeading:"Improved Operational Efficiency",
        Benefits_firstDescription:" Optimised upstream, midstream, and downstream processes.",
        Benefits_secondHeading:"Enhanced Safety and Sustainability",
        Benefits_secondDescription:"Proactive monitoring and intelligent hazard response, reduced environmental impact.",
        Benefits_thirdeading:"Optimised Asset Management",
        Benefits_thirdDescription:"Streamlined maintenance and compliance for critical infrastructure.",
        Benefits_fourthHeading:"Informed Decision-making",
        Benefits_fourthDescription:"Actionable insights and data-driven strategic planning.",
      },
      // Utilities Page 11 Done
      {
        heading: "Utilities",
        route_url: "utilities",
        Main_Heading:"Utilities",
        Main_title:
          "Transforming the Future of <br/> <span style='color: #7ebcb1;'>Energy and Water Management</span>",
        Main_Description:
          "The utilities industry, encompassing electricity, gas, and water services, plays a vital role in supporting modern society. TechGV is committed to empowering utility companies with innovative technology solutions that enhance operational efficiency, improve customer experience, and drive sustainable practices.",
        Main_img: HerosectionING,
        // Key Challenges Section
        Key_Challenges_Main_Heading:"Key Challenges in the Utilities Industry",
        Key_Challenges_Main_Description:"The utility industry faces challenges like ageing infrastructure, regulatory compliance, demand forecasting, and integrating renewable energy sources.",
        Key_Features_Img:WantToSee,
        Key_Features_firstHeading:"Grid modernisation and Smart Infrastructure",
        Key_Features_firstDescription:"Upgrading ageing infrastructure and integrating smart technologies.",
        Key_Features_secondHeading:"Renewable Energy Integration",
        Key_Features_secondDescription:"Effectively integrating renewable energy sources into the grid.",
        Key_Features_thirdeading:"Customer Engagement and Demand Management",
        Key_Features_thirdDescription:"Enhancing customer experience and Optimising energy/water demand.",
        Key_Features_fourthHeading:"Asset Management and Maintenance",
        Key_Features_fourthDescription:" Effective management and maintenance of critical utility infrastructure.",
        // Solutions Section
        Solutions_Main_Heading:"TechGV's Utilities Industry Solutions",
        Solutions_Main_Description:"TecHGV provides utility industry solutions that enhance infrastructure management, improve regulatory compliance, and optimise resource allocation for reliable service delivery.",
        Solutions_Img:WantToSee,
        Solutions_firstHeading:"Grid modernisation and Smart Infrastructure",
        Solutions_firstDescription:"<ul><li>Advanced metering infrastructure (AMI) and smart grid technologies.</li><li>Intelligent distribution automation and outage management systems.</li><li>Integrated network management and Optimisation platforms.</li></ul>",
        Solutions_secondHeading:"Renewable Energy Integration",
        Solutions_secondDescription:"<ul><li>Renewable energy forecasting and grid integration Optimisation.</li><li>Distributed energy resource management systems (DERMS).</li><li>Energy storage and microgrid solutions.</li></ul>",
        Solutions_thirdeading:"Customer Engagement and Demand Management",
        Solutions_thirdDescription:"<ul><li>Personalised customer portals and mobile applications.</li><li>Demand response and load management programs.</li><li>Energy efficiency and conservation initiatives.</li></ul>",
        Solutions_fourthHeading:"Asset Management and Maintenance",
        Solutions_fourthDescription:"<ul><li>RFID and GPS-enabled asset tracking and maintenance planning.</li><li>Predictive maintenance models for critical utility infrastructure.</li><li>Automated reporting and compliance documentation.</li></ul>",
        // Benefits Section
        Benefits_Main_Heading:"Benefits for Utilities Companies",
        Benefits_Main_Description:"Utility companies benefit from better infrastructure management, improved compliance, optimised resource allocation, and enhanced service reliability.",
        Benefits_Img:BenefitsImg,
        Benefits_firstHeading:"Improved Grid Reliability and Resilience",
        Benefits_firstDescription:"Enhanced grid modernisation and smart infrastructure.",
        Benefits_secondHeading:"Increased Renewable Energy Integration",
        Benefits_secondDescription:"Optimised renewable energy generation and grid integration.",
        Benefits_thirdeading:"Enhanced Customer Experience",
        Benefits_thirdDescription:"Personalized engagement and improved demand management.",
        Benefits_fourthHeading:"OptimisedAsset Management",
        Benefits_fourthDescription:"Streamlined maintenance and compliance for critical infrastructure.",
      },
      // Construction Page 12 Done
      {
        heading: "Construction",
        route_url: "construction",
        Main_Heading:"Construction",
        Main_title:
          "Empowering the Built Environment <br/> <span style='color: #7ebcb1;'>with Innovative Technologies</span>",
        Main_Description:
          "The construction industry plays a crucial role in shaping our physical world, from residential and commercial buildings to large-scale infrastructure projects. TechGV is dedicated to supporting construction companies with advanced technology solutions that enhance productivity, improve safety, and drive sustainability.",
        Main_img: HerosectionING,
        // Key Challenges Section
        Key_Challenges_Main_Heading:"Key Challenges in the Construction Industry",
        Key_Challenges_Main_Description:"The construction industry faces project delays, cost overruns, workforce shortages, and regulatory compliance.",
        Key_Features_Img:WantToSee,
        Key_Features_firstHeading:"Project Management and Collaboration",
        Key_Features_firstDescription:"Streamlining workflows and enabling effective collaboration.",
        Key_Features_secondHeading:"Workforce Productivity and Safety",
        Key_Features_secondDescription:"Improving worker productivity and safeguarding job site operations.",
        Key_Features_thirdeading:"Sustainable and Efficient Construction",
        Key_Features_thirdDescription:"Reducing the environmental impact and resource consumption.",
        Key_Features_fourthHeading:"Asset Management and Maintenance",
        Key_Features_fourthDescription:"Effective management and maintenance of construction equipment and materials.",
        // Solutions Section
        Solutions_Main_Heading:"TechGV's Construction Industry Solutions",
        Solutions_Main_Description:"TecHGV offers construction industry solutions that improve project management, enhance compliance, and optimise resource allocation for efficient project delivery.",
        Solutions_Img:WantToSee,
        Solutions_firstHeading:"Project Management and Collaboration",
        Solutions_firstDescription:"<ul><li>Cloud-based project management and BIM software.</li><li>Integrated communication and document management platforms.</li><li>Augmented reality (AR) and mixed reality (MR) applications.</li></ul>",
        Solutions_secondHeading:"Workforce Productivity and Safety",
        Solutions_secondDescription:"<ul><li>Wearable technology and IoT sensors for worker monitoring.</li><li>Automated safety and hazard detection systems.</li><li>Robotics and autonomous equipment for hazardous tasks.</li></ul>",
        Solutions_thirdeading:"Sustainable and Efficient Construction",
        Solutions_thirdDescription:"<ul><li>Building Information Modelling(BIM) for sustainable design.</li><li>Prefabrication and modular construction technologies.</li><li>Renewable energy and energy-efficient building materials.</li></ul>",
        Solutions_fourthHeading:"Asset Management and Maintenance",
        Solutions_fourthDescription:"<ul><li>RFID and GPS-enabled equipment tracking and maintenance planning.</li><li>Predictive maintenance models for critical construction assets.</li><li>Automated reporting and compliance documentation.</li></ul>",
        // Benefits Section
        Benefits_Main_Heading:"Benefits for Construction Companies",
        Benefits_Main_Description:"Construction companies experience improved project management, reduced delays, better resource allocation, and enhanced compliance with regulations.",
        Benefits_Img:BenefitsImg,
        Benefits_firstHeading:"Improved Project Delivery",
        Benefits_firstDescription:" Streamlined workflows, enhanced collaboration, and reduced delays.",
        Benefits_secondHeading:"Increased Workforce Productivity and Safety",
        Benefits_secondDescription:"Optimised job site operations and improved worker well-being.",
        Benefits_thirdeading:"Sustainable and Efficient Construction Practices",
        Benefits_thirdDescription:"Reduced environmental impact and resource consumption.",
        Benefits_fourthHeading:"Optimised Asset Management",
        Benefits_fourthDescription:"Streamlined maintenance and compliance for construction equipment and materials.",
      },
      // Field Services Page 13 Done
      {
        heading: "Field Services",
        route_url: "field-services",
        Main_Heading:"Field Services",
        Main_title:
          "Transforming Mobile Workforce <br/> <span style='color: #7ebcb1;'>Efficiency and Customer Experience</span>",
        Main_Description:
          "The field services industry, encompassing a wide range of sectors such as telecommunications, utilities, and facilities management, plays a critical role in delivering essential services to customers. TechGV is committed to empowering field service organisations with innovative technology solutions that enhance workforce productivity, improve customer satisfaction, and drive operational efficiency.",
        Main_img: HerosectionING,
        // Key Challenges Section
        Key_Challenges_Main_Heading:"Key Challenges in the Field Services Industry",
        Key_Challenges_Main_Description:"The field services industry faces challenges like scheduling inefficiencies, communication gaps, and resource management.",
        Key_Features_Img:WantToSee,
        Key_Features_firstHeading:"Workforce Optimisation and Scheduling",
        Key_Features_firstDescription:"Efficiently dispatching and managing a mobile workforce.",
        Key_Features_secondHeading:"Remote Workforce Enablement",
        Key_Features_secondDescription:"Equipping field technicians with the tools and information they need.",
        Key_Features_thirdeading:"Customer Experience and Engagement",
        Key_Features_thirdDescription:"Providing a seamless and responsive customer experience.",
        Key_Features_fourthHeading:"Asset Management and Maintenance",
        Key_Features_fourthDescription:"Effective management and maintenance of field equipment and infrastructure.",
        // Solutions Section
        Solutions_Main_Heading:"TechGV's Field Services Industry Solutions",
        Solutions_Main_Description:"TecHGV provides field services solutions that streamline scheduling, enhance communication, and optimise resource management for improved service delivery.",
        Solutions_Img:WantToSee,
        Solutions_firstHeading:" Workforce Optimisation and Scheduling",
        Solutions_firstDescription:"<ul><li>Intelligent workforce management and scheduling platforms.</li><li>Mobile workforce tracking and route Optimisation.</li><li>Automated dispatch and task allocation systems.</li></ul>",
        Solutions_secondHeading:"Remote Workforce Enablement",
        Solutions_secondDescription:"<ul><li>Rugged mobile devices and enterprise-grade applications.</li><li>Augmented reality (AR) and mixed reality (MR) for remote assistance.</li><li>Knowledge management and collaboration tools for field technicians.</li></ul>",
        Solutions_thirdeading:"Customer Experience and Engagement",
        Solutions_thirdDescription:"<ul><li>Integrated customer portals and mobile applications.</li><li>Automated service requests and appointment scheduling.</li><li>Real-time service status updates and proactive customer notifications.</li></ul>",
        Solutions_fourthHeading:"Asset Management and Maintenance",
        Solutions_fourthDescription:"<ul><li>RFID and GPS-enabled asset tracking and maintenance planning.</li><li>Predictive maintenance models for critical field equipment.</li><li>Automated reporting and compliance documentation.</li></ul>",
        // Benefits Section
        Benefits_Main_Heading:"Benefits for Field Service organisations",
        Benefits_Main_Description:"Field services companies gain streamlined scheduling, improved communication, better resource management, and enhanced customer satisfaction.",
        Benefits_Img:BenefitsImg,
        Benefits_firstHeading:"Improved Workforce Productivity",
        Benefits_firstDescription:"Optimised scheduling, routing, and task allocation.",
        Benefits_secondHeading:"Enhanced Remote Worker Capabilities",
        Benefits_secondDescription:"Empowered field technicians with the right tools and information.",
        Benefits_thirdeading:"Elevated Customer Experience",
        Benefits_thirdDescription:"Personalized service and responsive customer engagement.",
        Benefits_fourthHeading:"Optimised Asset Management",
        Benefits_fourthDescription:"Streamlined maintenance and compliance for field equipment.",
      },
      // Commercial Fleets Page 14 Done
      {
        heading: "Commercial Fleets",
        route_url: "commercial-fleets",
        Main_Heading:"Commercial Fleets",
        Main_title:
          "Driving Efficiency, Safety, and<br/> <span style='color: #7ebcb1;'>Sustainability in Fleet Operations</span>",
        Main_Description:
          "Commercial fleets, including those in the construction, field services, and transportation sectors, play a vital role in the delivery of goods, services, and infrastructure. TechGV is dedicated to empowering commercial fleet operators with innovative technology solutions that enhance fleet efficiency, improve driver safety, and promote sustainability.",
        Main_img: HerosectionING,
        // Key Challenges Section
        Key_Challenges_Main_Heading:"Key Challenges in the Commercial Fleets Industry",
        Key_Challenges_Main_Description:"The commercial fleet industry faces challenges such as rising fuel costs, vehicle maintenance, regulatory compliance, optimising routes, and managing driver performance.",
        Key_Features_Img:WantToSee,
        Key_Features_firstHeading:"Fleet Optimisation and Productivity",
        Key_Features_firstDescription:"Streamlining fleet management and improving Utilisation.",
        Key_Features_secondHeading:"Driver Safety and Compliance",
        Key_Features_secondDescription:"Ensuring the safety of drivers and compliance with regulations.",
        Key_Features_thirdeading:"Sustainability and Emissions Reduction",
        Key_Features_thirdDescription:"Minimising the environmental impact of fleet operations.",
        Key_Features_fourthHeading:"Asset Management and Maintenance",
        Key_Features_fourthDescription:"Effective management and maintenance of fleet vehicles and equipment.",
        // Solutions Section
        Solutions_Main_Heading:"TechGV's Commercial Fleets Industry Solutions",
        Solutions_Main_Description:"TecHGV offers commercial fleet solutions that optimize route planning, enhance fleet management, and ensure regulatory compliance for improved efficiency and cost savings.",
        Solutions_Img:WantToSee,
        Solutions_firstHeading:"Fleet Optimisation and Productivity",
        Solutions_firstDescription:"<ul><li>Fleet management and telematics platforms.</li><li>Route planning and Optimisation algorithms.</li><li>Predictive maintenance and logistics automation.</li></ul>",
        Solutions_secondHeading:"Driver Safety and Compliance",
        Solutions_secondDescription:"<ul><li>Intelligent driver monitoring and coaching systems.</li><li>Advanced driver assistance technologies (ADAS).</li><li>Electronic logging devices (ELDs) and compliance reporting.</li></ul>",
        Solutions_thirdeading:"Sustainability and Emissions Reduction",
        Solutions_thirdDescription:"<ul><li>Electric and alternative fuel vehicle integration.</li><li>Optimisation of fuel consumption and emissions.</li><li>Renewable energy and carbon offset programs.</li></ul>",
        Solutions_fourthHeading:"Asset Management and Maintenance",
        Solutions_fourthDescription:"<ul><li>RFID and GPS-enabled asset tracking and maintenance planning.</li><li>Predictive maintenance models for fleet vehicles and equipment.</li><li>Automated reporting and compliance documentation.</li></ul>",
        // Benefits Section
        Benefits_Main_Heading:"Benefits for Commercial Fleet Operators",
        Benefits_Main_Description:"Commercial fleet companies benefit from optimised route planning, reduced fuel costs, improved vehicle maintenance, and enhanced operational efficiency.",
        Benefits_Img:BenefitsImg,
        Benefits_firstHeading:"Improved Fleet Efficiency and Productivity",
        Benefits_firstDescription:"Streamlined fleet management and optimised vehicle Utilisation.",
        Benefits_secondHeading:"Enhanced Driver Safety and Compliance",
        Benefits_secondDescription:"Improved driver behaviour and compliance with regulations.",
        Benefits_thirdeading:"Reduced Environmental Impact",
        Benefits_thirdDescription:" Decreased fuel consumption, emissions, and carbon footprint.",
        Benefits_fourthHeading:"Optimised Asset Management",
        Benefits_fourthDescription:"Streamlined maintenance and compliance for fleet vehicles and equipment.",
      },
      // Waste Collection Page 15 Done
      {
        heading: "Waste Collection",
        route_url: "waste-collection",
        Main_Heading:"Waste Collection",
        Main_title:
          "Transforming Efficiency and Sustainability in<br/> <span style='color: #7ebcb1;'>Municipal and Commercial Waste Management</span>",
        Main_Description:
          "Effective waste collection is the foundation of a comprehensive waste management system, whether in municipal or commercial settings. TechGV is dedicated to providing innovative technology solutions that optimize waste collection operations, enhance environmental sustainability, and improve overall waste management efficiency.",
        Main_img: HerosectionING,
        // Key Challenges Section
        Key_Challenges_Main_Heading:"Key Challenges in Waste Collection",
        Key_Challenges_Main_Description:"Waste collection faces challenges like inefficient routing, regulatory compliance, rising costs, and environmental concerns.",
        Key_Features_Img:WantToSee,
        Key_Features_firstHeading:"Route Optimisation and Fleet Management",
        Key_Features_firstDescription:"Streamlining waste collection routes and managing fleet assets.",
        Key_Features_secondHeading:"Waste Diversion and Recycling",
        Key_Features_secondDescription:"Increasing recycling rates and reducing landfill waste.",
        Key_Features_thirdeading:"Operational Efficiency and Productivity",
        Key_Features_thirdDescription:"Improving the overall productivity and cost-effectiveness of waste collection services.",
        Key_Features_fourthHeading:"Regulatory Compliance and Reporting",
        Key_Features_fourthDescription:"Ensuring compliance with evolving environmental regulations and reporting requirements.",
        // Solutions Section
        Solutions_Main_Heading:"TechGV's Waste Collection Solutions",
        Solutions_Main_Description:"TecHGV provides waste collection solutions that optimise routing, ensure regulatory compliance, and enhance operational efficiency for effective waste management.",
        Solutions_Img:WantToSee,
        Solutions_firstHeading:"Route Optimisation and Fleet Management",
        Solutions_firstDescription:"<ul><li>Advanced route planning and Optimisation algorithms.</li><li>Fleet management and telematics platforms.</li><li>Predictive maintenance and logistics automation.</li></ul>",
        Solutions_secondHeading:"Waste Diversion and Recycling",
        Solutions_secondDescription:"<ul><li>Intelligent bin and container monitoring systems.</li><li>Automated waste sorting and material recovery technologies.</li><li>Citizen and commercial customer education and engagement.</li></ul>",
        Solutions_thirdeading:"Operational Efficiency and Productivity",
        Solutions_thirdDescription:"<ul><li>Automated waste collection and compaction systems.</li><li>Workforce management and scheduling platforms.</li><li>Integration with smart city and IoT infrastructure.</li></ul>",
        Solutions_fourthHeading:"Regulatory Compliance and Reporting",
        Solutions_fourthDescription:"<ul><li>Integrated compliance and reporting dashboards.</li><li>Automated documentation and data management.</li><li>Collaboration and knowledge management systems.</li></ul>",
        // Benefits Section
        Benefits_Main_Heading:"Benefits for Waste Management Authorities and Operators",
        Benefits_Main_Description:"Waste collection companies enjoy optimised routing, improved operational efficiency, enhanced compliance, and better customer satisfaction.",
        Benefits_Img:BenefitsImg,
        Benefits_firstHeading:"Improved Collection Efficiency",
        Benefits_firstDescription:"Optimised routes, reduced fleet costs, and enhanced workforce productivity.",
        Benefits_secondHeading:"Increased Waste Diversion and Recycling",
        Benefits_secondDescription:"Higher recycling rates, reduced landfill waste, and improved environmental sustainability.",
        Benefits_thirdeading:"Enhanced Operational Productivity",
        Benefits_thirdDescription:"Streamlined waste collection processes and cost-effective service delivery.",
        Benefits_fourthHeading:"Robust Compliance and Reporting",
        Benefits_fourthDescription:"Automated documentation and data management for regulatory requirements.",
      },
      // Recycling Services Page 16
      {
        heading: "Recycling Services",
        route_url: "recycling-services",
        Main_Heading:"Recycling Services",
        Main_title:
          "Driving a Circular Economy<br/> <span style='color: #7ebcb1;'>Through Advanced Technologies</span>",
        Main_Description:
          "In the evolving landscape of waste management, recycling plays a pivotal role in transitioning towards a more sustainable, circular economy. TechGV is at the forefront of delivering innovative recycling solutions that maximise resource recovery, improve operational efficiency, and foster environmental stewardship.",
        Main_img: HerosectionING,
        // Key Challenges Section
        Key_Challenges_Main_Heading:"Key Challenges in Recycling Services",
        Key_Challenges_Main_Description:"Recycling services face challenges such as contamination of recyclable materials, fluctuating market prices, regulatory compliance, public awareness, and efficient logistics management.",
        Key_Features_Img:WantToSee,
        Key_Features_firstHeading:"Increasing Recycling Rates",
        Key_Features_firstDescription:"Driving higher participation and diversion of recyclable materials from landfills.",
        Key_Features_secondHeading:"Improving Sorting and Material Recovery",
        Key_Features_secondDescription:"Enhancing the quality and purity of recovered materials.",
        Key_Features_thirdeading:"Optimising Recycling Operations",
        Key_Features_thirdDescription:"Streamlining the logistics and processing of recyclable materials.",
        Key_Features_fourthHeading:"Fostering Circular Economy Initiatives",
        Key_Features_fourthDescription:"Promoting the reuse and repurposing of recovered materials.",
        // Solutions Section
        Solutions_Main_Heading:"TechGV's Recycling Services Solutions",
        Solutions_Main_Description:"TecHGV offers recycling services solutions that enhance material sorting, improve operational efficiency, and ensure compliance with regulations for effective waste management.",
        Solutions_Img:WantToSee,
        Solutions_firstHeading:"Increasing Recycling Rates",
        Solutions_firstDescription:"<ul><li>Intelligent bin and container monitoring systems.</li><li>Citizen and commercial customer education and engagement programs.</li><li>Incentive-based recycling and waste diversion initiatives.</li></ul>",
        Solutions_secondHeading:"Improving Sorting and Material Recovery",
        Solutions_secondDescription:"<ul><li>Advanced optical sorting and automated material identification technologies.</li><li>AI-powered quality control and contamination detection systems.</li><li>Integrated recycling facility management platforms.</li></ul>",
        Solutions_thirdeading:"Optimising Recycling Operations",
        Solutions_thirdDescription:"<ul><li>Route Optimisation and logistics automation for collection.</li><li>Robotic and automated processing equipment for material handling.</li><li>Predictive maintenance and smart infrastructure integration.</li></ul>",
        Solutions_fourthHeading:"Fostering Circular Economy Initiatives",
        Solutions_fourthDescription:"<ul><li>Collaborative platforms for materials exchange and reuse.</li><li>Life cycle assessment and closed-loop product design support.</li><li>Integration with waste-to-energy and upcycling technologies.</li></ul>",
        // Benefits Section
        Benefits_Main_Heading:"Benefits for Waste Management Authorities and Recycling Operators",
        Benefits_Main_Description:"Recycling companies gain enhanced material sorting, improved compliance with regulations, reduced contamination, and better operational efficiency.",
        Benefits_Img:BenefitsImg,
        Benefits_firstHeading:"Increased Recycling Rates",
        Benefits_firstDescription:"Higher participation and diversion of recyclable materials from landfills.",
        Benefits_secondHeading:"Improved Material Recovery and Quality",
        Benefits_secondDescription:"Enhanced sorting, purity, and value of recovered materials.",
        Benefits_thirdeading:"Optimised Recycling Operations",
        Benefits_thirdDescription:"Streamlined logistics, processing, and cost-effective service delivery.",
        Benefits_fourthHeading:"Fostered Circular Economy",
        Benefits_fourthDescription:"Increased reuse, repurposing, and sustainable material flows.",
      },
    ],
  },
  // {
  //   link_title: "Pricing",
  //   route: "/pricing",
  //   heading: "By industry",
  //   multiRoutes: [
  //     {
  //       heading: "Tracking Solutions",
  //       icon: "",
  //       route: "tracking-solutions",
  //       routes: [
  //         "Driving History Section",
  //         "Tracking Section",
  //         "Tracking Section",
  //       ],
  //     },
  //     {
  //       heading: "Tracking Solutions",
  //       icon: "",
  //       routes: ["Driving History Section", "Tracking Section"],
  //     },
  //     {
  //       heading: "Tracking Solutions",
  //       icon: "",
  //       routes: ["Driving History Section", "Tracking Section"],
  //     },
  //     {
  //       heading: "Tracking Solutions",
  //       icon: "",
  //       routes: ["Driving History Section", "Tracking Section"],
  //     },
  //     {
  //       heading: "Tracking Solutions",
  //       icon: "",
  //       routes: ["Driving History Section", "Tracking Section"],
  //     },
  //   ],
  // },
  {
    link_title: "Order a Device",
    route: "order-a-device",
    heading1: "Featured1",
    multiRoutes: [
      {
        heading: "Tracking Solutions",
        icon: "",
        routes: [
          "Driving History Section",
          "Tracking Section",
          "Tracking Section",
        ],
      },
      {
        heading: "Tracking Solutions",
        icon: "",
        routes: ["Driving History Section", "Tracking Section"],
      },
    ],
  },
  { link_title: "Book a Demo", route: "book-a-demo", heading1: "Featured1" },
  {
    link_title: "Get a Free Quote",
    heading1: "Featured1",
    route: "Get-a-Free-Quote",
  },
  { link_title: "Blog", heading1: "Featured1", route: "blog" },
];
