import React, { useEffect, useState } from "react";
import "components/ServicesPages/style.css";
// import FuelAdblueUsageimg from "assets/img/Fuel-Adblu-Usage-mobile-1.png";
// import TorqueRegisterimg from "assets/img/Torque-Register-mobile-1.png";
// import DriverChecksimg from "assets/img/Driver-Checks-mobile-1.png";
// import RequestADemo from "assets/img/Vehicle-Alignment.jpeg";
// import PhoneImg from "assets/img/main-img-1.png";
import { Link, useLocation, useParams } from "react-router-dom";
// import { ServicesPage_Data } from "data/ServicesPage";
import { Outlet } from "react-router-dom";
import { Header_Data } from "data/Header";

const Products = ({}) => {
  const currentLocation = useLocation();
  const [routedata, setRoutedata]=useState([...Header_Data])
  const fetch_routes=Header_Data.find((item)=>item.link_title.toLocaleLowerCase() ==  currentLocation.pathname.split('/')[1])
  const { state } = currentLocation || {};
  const  {current_item}  = state  || {current_item:fetch_routes.multiRoutes.find((item)=>item.route_url == currentLocation.pathname.split('/')[2])} ;


  return (
    <div>
      <>
        <div className="services-page-section">
          <div className="container">
            <div className="row">
              <div className="main-heading col-lg-6">
                <h1
                  style={{ padding: "0px" }}
                  dangerouslySetInnerHTML={{ __html: current_item?.Main_title }}
                />

                <p
                  style={{ textAlign: "start" }}
                  dangerouslySetInnerHTML={{
                    __html: current_item?.Main_Description,
                  }}
                />
              </div>
              <div className="col-lg-6" style={{ textAlign: "end" }}>
                <img
                  className="phone-2"
                  src={current_item?.Main_img}
                  alt={current_item?.Main_title}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="ServicesPage-Driver-Checks-sec">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2 style={{ padding: "0px 0px 20px 0px" }}>Key Features</h2>
              </div>
            </div>
            <div className="row align-center">
              <div className="col-md-4 text-center">
                <div className="feature-1">
                  <img
                    className="DriverChecksimg"
                    src={current_item?.KeyFeatures_firstImg}
                    alt={current_item?.KeyFeatures_firstImg}
                  />
                  <h3 className="mb-3">
                    {current_item?.KeyFeatures_firstHeading}
                  </h3>
                  <p>{current_item?.KeyFeatures_firstDescription}</p>
                </div>
              </div>
              <div className="col-md-4 text-center">
                <div className="feature-1">
                  <img
                    className="DriverChecksimg"
                    src={current_item?.KeyFeatures_secondImg}
                    alt={current_item?.KeyFeatures_secondImg}
                  />
                  {/* <h3 className="mb-3">Historical Data Analysis</h3> */}
                  <h3 className="mb-3">
                    {current_item?.KeyFeatures_secondHeading}
                  </h3>
                  <p>{current_item?.KeyFeatures_secondDescription}</p>
                </div>
              </div>
              <div className="col-md-4 text-center">
                <div className="feature-1 ">
                  <img
                    className="DriverChecksimg"
                    src={current_item?.KeyFeatures_thirdImg}
                    alt={current_item?.KeyFeatures_thirdImg}
                  />
                  {/* <h3 className="mb-3">Driver Behavior Assessment</h3> */}
                  <h3 className="mb-3">
                    {current_item?.KeyFeatures_thirdHeading}
                  </h3>
                  <p>{current_item?.KeyFeatures_thirdDescription}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="Benefits-Driver-Checks-sec">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2 style={{ padding: "0px 0px 20px 0px" }}>Benefits</h2>
              </div>
            </div>
            <div className="row align-center">
              <div className="col-md-3 text-center">
                <div className="feature-1">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: current_item?.Benefits_firstImg,
                    }}
                  />
                  {/* <img
                    
                    src={current_item?.Benefits_firstImg}
                    alt={current_item?.Benefits_firstImg}
                  /> */}
                  <h3
                    className="mb-3"
                    dangerouslySetInnerHTML={{
                      __html: current_item?.Benefits_firstHeading,
                    }}
                  />
                  <p>{current_item?.Benefits_firstDescription}</p>
                </div>
              </div>
              <div className="col-md-3 text-center">
                <div className="feature-1">
                <span
                    dangerouslySetInnerHTML={{
                      __html: current_item?.Benefits_firstImg,
                    }}
                  />
                  <h3
                    className="mb-3"
                    dangerouslySetInnerHTML={{
                      __html: current_item?.Benefits_secondHeading,
                    }}
                  />
                  <p>{current_item?.Benefits_secondDescription}</p>
                </div>
              </div>
              <div className="col-md-3 text-center">
                <div className="feature-1">
                <span
                    dangerouslySetInnerHTML={{
                      __html: current_item?.Benefits_firstImg,
                    }}
                  />
                  <h3
                    className="mb-3"
                    dangerouslySetInnerHTML={{
                      __html: current_item?.Benefits_thirdHeading,
                    }}
                  />
                  <p>{current_item?.Benefits_thirdDescription}</p>
                </div>
              </div>
              <div className="col-md-3 text-center">
                <div className="feature-1">
                {/* <img
                    
                    src={current_item?.Benefits_firstImg}
                    alt={current_item?.Benefits_firstImg}
                  /> */}
                  <span
                    dangerouslySetInnerHTML={{
                      __html: current_item?.Benefits_firstImg,
                    }}
                  />
                  <h3
                    className="mb-3"
                    dangerouslySetInnerHTML={{
                      __html: current_item?.Benefits_4thHeading,
                    }}
                  />

                  <p>{current_item?.Benefits_4thDescription}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="Use-Cases-Driver-Checks-sec">
          <div className="container">
            <div className="row" style={{ paddingTop: "0px" }}>
              <div className="col-md-12">
                <h2 style={{ padding: "0px 0px 0px 0px" }}>Use Cases</h2>
              </div>
            </div>
            <div className="row align-center">
              <div className="col-md-4 BoxShadow">
                <div className="feature-2">
                  <img
                    className="Use-Cases-img"
                    src={current_item?.UseCases_firstImg}
                    alt={current_item?.UseCases_firstHeading}
                  />
                  <div className="feature-1">
                    <h3 className="mb-3">
                      {current_item?.UseCases_firstHeading}
                    </h3>
                    <p>{current_item?.UseCases_firstDescription}</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 BoxShadow">
                <div className="feature-2">
                  <img
                    className="Use-Cases-img"
                    src={current_item?.UseCases_secondImg}
                    alt={current_item?.UseCases_secondHeading}
                  />
                  <div className="feature-1">
                    <h3 className="mb-3">
                      {current_item?.UseCases_secondHeading}
                    </h3>
                    <p>{current_item?.UseCases_secondDescription}</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 BoxShadow">
                <div className="feature-2">
                  <img
                    className="Use-Cases-img"
                    src={current_item?.UseCases_thirdImg}
                    alt={current_item?.UseCases_firstHeading}
                  />
                  <div className="feature-1">
                    <h3 className="mb-3">
                      {current_item?.UseCases_thirdHeading}
                    </h3>
                    <p>{current_item?.UseCases_thirdDescription}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="Getting-Started-sec">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2 style={{ padding: "0px 0px 20px 0px" }}>
                  Getting Started <br />
                  <span style={{ color: "#7ebcb1" }}> with TecHGV </span>
                </h2>
                <p>
                {current_item?.Getting_StartedMainHeading}
                  {/* Implementing driving history tracking in your fleet is simple: */}
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="feature-1" style={{ paddingTop: "7em" }}>
                  <h3 className="mb-3">
                    {/* {current_item?.GettingStarted_firstHeading} */}
                    {current_item?.Getting_firstHeading}
                  </h3>
                  <p>{current_item?.Getting_firstDescription}</p>
                </div>
              </div>
              <div className="col-md-6" style={{ paddingTop: "15px" }}>

                <img
                    className="phone-2"
                    src={current_item?.Getting_firstImg}
                    alt={current_item?.Getting_firstHeading}
                  />
              </div>
            </div>
            <div className="row" style={{ padding: "40px 0px 40px 0px" }}>
              <div className="col-md-6" style={{ paddingTop: "15px" }}>
                <img className="phone-2" 
                src={current_item?.Getting_secondImg}
                    alt={current_item?.Getting_secondHeading} />
              </div>
              <div className="col-md-6">
                <div className="feature-1" style={{ paddingTop: "7em" }}>
                  <h3 className="mb-3">{current_item?.Getting_secondHeading}</h3>
                  <p>
                  {current_item?.Getting_secondDescription}
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="feature-1" style={{ paddingTop: "7em" }}>
                  <h3 className="mb-3">{current_item?.Getting_thirdHeading}</h3>
                  <p>
                  {current_item?.Getting_thirdDescription}
                  </p>
                </div>
              </div>
              <div className="col-md-6" style={{ paddingTop: "15px" }}>
                <img className="phone-2"  
                src={current_item?.Getting_thirdImg}
                    alt={current_item?.Getting_thirdHeading} />
              </div>
            </div>
          </div>
        </div>
        <div className="Call-to-Action-sec">
          <div className="container">
            {/* <div className="row">
            <div className="col-md-12">
              <h2 style={{ padding: "0px 0px 20px 0px" }}>Use Cases</h2>
            </div>
          </div> */}
            <div className="row ase">
              <div
                className="col-md-8"
                style={{
                  backgroundColor: "#7ebcb1",
                  height: "200px",
                  marginTop: "auto",
                  width: "45%",
                  padding: "50px 30px 50px 30px",
                  textAlign: "start",
                }}
              >
                <div className="feature-1">
                  <h3 className="mb-3">Get Started Today</h3>
                  <p style={{ textAlign: "start" }}>
                    Motive can automate and scale your operations
                  </p>
                  <br />
                  <Link
                    to="/your-path"
                    className="btn btn-light"
                    role="button"
                    style={{ padding: "5px 30px 5px 30px" }}
                  >
                    Get a Free Quote
                  </Link>
                </div>
              </div>
              <div className="col-md-4"></div>
            </div>
          </div>
        </div>
      </>

      <Outlet />
    </div>
  );
};


export default Products;
