import React from "react";
import "components/DropdownsPage/index.css";
import { Link } from "react-router-dom";

const DropdownsPage = ({ data, features, index, heading, onClick }) => {
  return (
    <div>
      <div className="menu-dropdown-section">
        <div className="container">
          <div className="row py-0">
            <div className="col-md-12">
              <div className="row py-0">
                <div className="products1 col-md-12`">
                  <h4>{heading}</h4>
                  <div className="row py-0">
                    {data?.map((item, index) => (
                      <div className="col-md-3">
                        <ul className="list-unstyled d-flex">
                          <li>
                            <div className="icon">💳</div>
                            <div>
                              <div
                                onClick={() => onClick(item, index)}
                                style={{
                                  textDecoration: "none",
                                  marginBottom: 3,
                                  cursor:'pointer'
                                }}
                              >
                                <p style={{ fontSize: 13, fontWeight: "bold" }}>
                                  {item?.heading}
                                </p>
                              </div>
                              {item?.routes?.map((item) => (
                                <p style={{ fontSize: 12 }}>{item}</p>
                              ))}
                            </div>
                          </li>
                        </ul>
                      </div>
                    ))}
                  </div>
                  {index == 0 && (
                    <Link
                      to="#"
                      className="view-all"
                      style={{ textDecoration: "none", marginBottom: 3 }}
                    >
                      View All Products →
                    </Link>
                  )}
                </div>
                {/* <div className="featured1 col-md-3"></div> */}
              </div>
            </div>
            {/* {features && (
              <div
                className="featured1 col-md-3"
                style={{ borderLeft: "1px solid #cfc8c8" }}
              >
                <h4>Resources</h4>
                <ul className="list-unstyled">
                  {features?.map((item) => (
                    <>
                      <p
                        style={{
                          fontSize: 13,
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {item}
                      </p>
                      <li>
                        <Link
                          to="#"
                          style={{ textDecoration: "none", marginBottom: 3 }}
                        >
                          <p style={{ fontSize: 12 }}>{item}</p>
                        </Link>
                      </li>
                    </>
                  ))}
                </ul>
              </div>
            )} */}
            {/* <div className="image col-md-3">
              <img
                src="https://via.placeholder.com/400x200"
                alt="ROI Savings Calculator" 
              />
              <h5>How much will you save with Motive?</h5>
              <p>
                Improve your bottom line with Motive's cutting-edge technology
                that enhances safety, boosts productivity, and drives
                profitability.
              </p>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DropdownsPage;
