import React, { createContext, useEffect, useState } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import "@fortawesome/fontawesome-svg-core";
import "@fortawesome/free-brands-svg-icons";
import "@fortawesome/react-fontawesome";
import Header from "components/header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import Home from "pages/Home/Home.js";
import BookAdemo from "components/BookDemo/BookADemo.jsx";
import OrderDevice from "components/OrderaDevice/OrderADevice.jsx";
import RequestAQuote from "components/RequestAQuote/RequestAQuote.jsx";
import Blog from "components/Blog/Blog.jsx";
import posts from "components/Blog/Post.jsx";
import FleetManagementServicesSolutions from "components/Blog/FleetManagementServicesSolutions.jsx";
import CommercialVehicleTrackingSystem from "components/Blog/CommercialVehicleTrackingSystem.jsx";
import VehicleAlignment from "components/Blog/VehicleAlignment.jsx";
import VehicleDailyChecksImportance from "components/Blog/VehicleDailyChecksImportance.jsx";
import "animate.css";
import "App.css";
import { NavButtonClosed } from "StateManagement/Context";
import ScrollToTopOnNavigate from "components/ScrollToTopOnNavigate";
import PageNotFound from "components/404";
import PricingPlan from "components/PricingPlan.js/PricingPlan";
import PrivacyPolicy from "pages/Privacy Policy/PrivacyPolicy";
import Test from "components/test";
import DropdownsPage from "components/DropdownsPage/DropdownsPage";
import Products from "components/ServicesPages/Products";
import { ServicesPage_Data } from "data/ServicesPage";
import Industries from "components/Industries/Industries";

function App() {
  const [navButtonClosed, setNavButtonClosed] = useState(false);
  return (
    <Router>
      <ScrollToTopOnNavigate />
      <NavButtonClosed.Provider value={{ navButtonClosed, setNavButtonClosed }}>
        <Header />
      </NavButtonClosed.Provider>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="/book-a-demo"
          element={<BookAdemo title="Book A Demo" />}
        />
        <Route path="/blog" element={<Blog post={posts} />} />
        <Route
          path="/order-a-device"
          element={<OrderDevice title="Order a Device" />}
        />
        <Route
          path="/get-a-free-quote"
          element={<RequestAQuote title="Request A Quote" />}
        />
        <Route
          path="/fleet-management-services-solutions"
          element={
            <FleetManagementServicesSolutions title="Fleet Management Services Solutions" />
          }
        />
        <Route
          path="/commercial-vehicle-tracking-system1"
          element={
            <CommercialVehicleTrackingSystem title="Commercial Vehicle Tracking System" />
          }
        />
        <Route
          path="/vehicle-alignment"
          element={<VehicleAlignment title="VehicleAlignment" />}
        />
        <Route
          path="/vehicle-daily-checks-importance"
          element={
            <VehicleDailyChecksImportance title="Vehicle Daily Checks Importance" />
          }
        />
        <Route
          path="/pricing-plan"
          element={<PricingPlan title="Pricing Plan" />}
        />
        <Route
          path="/privacy-policy"
          element={<PrivacyPolicy title="Privacy Policy" />}
        />
        <Route
          path="/dropdowns-page"
          element={<DropdownsPage title="Dropdowns Page" />}
        />
        <Route path="/products" element={<Products />}>
          <Route path=":route_url" />
        </Route>
        {/* <Route path="/test" element={<Test title="test" />} /> */}
        <Route path="/industries" element={<Industries />}>
          <Route path=":route_url" />
        </Route>
        {/* <Route
          path="/industries"
          element={<Industries title="Industries" />}
        /> */}
        <Route path="*" element={<PageNotFound title="404" />} />
      </Routes>

      <Footer />
    </Router>
  );
}

export default App;
