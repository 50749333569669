import React from "react";
// import "bootstrap/dist/css/bootstrap.min.css";
import "components/Industries/style.css";
import HerosectionING from "assets/img/Industries-driving-img.png";
import WantToSee from "assets/img/Industries-features-img.png";

import VehicleAlignmentImg from "assets/img/Vehicle-Alignment.jpeg";
import { Outlet, useLocation } from "react-router-dom";
// const Industries = () => {
  const Industries = ({}) => {
    const currentLocation = useLocation();
  
    const { state } = currentLocation || {};
    const { current_item } = state;
  return (
    <>
      <div className="hero-section1 d-flex align-items-center">
        <div className="container">
          <div className="row">
            <div className="col-md-7">
              <h6> {current_item?.Main_Heading}</h6>
              <h1 className="display-4 font-weight-bold"
                  style={{ padding: "0px" }}
                  dangerouslySetInnerHTML={{ __html: current_item?.Main_title }}
                />
              <p
                  style={{ textAlign: "start" }}
                  dangerouslySetInnerHTML={{
                    __html: current_item?.Main_Description,
                  }}
                />
            </div>
            <div
              className="col-md-5"
              style={{
                textAlign: "end",
              }}
            >
              {/* <img  src={HerosectionING} /> */}
              <img className="phone-2"
                    
                    src={current_item?.Main_img}
                    alt={current_item?.Main_img}
                  />
            </div>
          </div>
        </div>
      </div>

      <div className="features-section">
        <div className="container">
          <div className="row" style={{ paddingBottom: "30px" }}>
            <div className="col-md-12">
              <h2>{current_item?.Key_Challenges_Main_Heading}</h2>
              <p>
                {current_item?.Key_Challenges_Main_Description}
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              {/* <img className="phone-2" src={WantToSee} /> */}
              <img className="phone-2"
                    
                    src={current_item?.Key_Features_Img}
                    alt={current_item?.Key_Features_Img}
                  />
            </div>
            <div className="col-md-6 OurAdvanced">
              <h5 className="font-weight-bold">{current_item?.Key_Features_firstHeading}</h5>
              <p>
                {current_item?.Key_Features_firstDescription}
              </p>
              <h5 className="font-weight-bold">{current_item?.Key_Features_secondHeading}</h5>
              <p>
                {current_item?.Key_Features_secondDescription}
              </p>
              <h5 className="font-weight-bold">{current_item?.Key_Features_thirdeading}</h5>
              <p>
                {current_item?.Key_Features_thirdDescription}
              </p>
              <h5 className="font-weight-bold">{current_item?.Key_Features_fourthHeading}</h5>
              <p>
                {current_item?.Key_Features_fourthDescription}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="features-section">
        <div className="container">
          <div className="row" style={{ paddingBottom: "30px" }}>
            <div className="col-md-12">
              <h2>{current_item?.Solutions_Main_Heading}</h2>
              <p>
                {current_item?.Solutions_Main_Description}
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 OurAdvanced">
              <h5 className="font-weight-bold">{current_item?.Solutions_firstHeading}</h5>
              <p  dangerouslySetInnerHTML={ {__html: current_item?.Solutions_firstDescription,} } />
              <h5 className="font-weight-bold">{current_item?.Solutions_secondHeading}</h5>
              <p  dangerouslySetInnerHTML={ {__html: current_item?.Solutions_secondDescription,} } />
            </div>
            <div className="col-md-4 OurAdvanced">
              <h5 className="font-weight-bold">{current_item?.Solutions_thirdeading}</h5>
              <p  dangerouslySetInnerHTML={ {__html: current_item?.Solutions_thirdDescription,} } />
              <h5 className="font-weight-bold">{current_item?.Solutions_fourthHeading}</h5>
              <p  dangerouslySetInnerHTML={ {__html: current_item?.Solutions_fourthDescription,} } />
            </div>
            <div className="col-md-4">
              <img className="phone-2"
                    
                    src={current_item?.Solutions_Img}
                    alt={current_item?.Solutions_Img}
                  />
            </div>
          </div>
        </div>
        </div>
       
        <div className="Card-sec">
          <div className="container">
            <div className="card demo-card d-flex flex-row">
              <div className="col-md-4">
                <div className="card-body">
                  <img className="phone-2" src={VehicleAlignmentImg} />
                </div>
              </div>
              <div className="col-md-8">
                <div className="card-body">
                  <h5 className="card-title">Want to see TechGV in action?</h5>
                  <p className="card-text">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                    ornare cursus sed nunc eget dictum. Lorem ipsum dolor sit
                    amet, consectetur adipiscing elit. Sed ornare cursus sed
                    nunc eget dictum. Lorem ipsum dolor sit amet, consectetur
                    adipiscing elit. Sed ornare cursus sed nunc eget dictum.
                  </p>
                  <a src="#" className="btn btn-custom">
                    Request demo →
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="features-section">
        <div className="container">
          <div className="row" style={{ paddingBottom: "30px" }}>
            <div className="col-md-12">
              <h2>{current_item?.Benefits_Main_Heading}</h2>
              <p>
                {current_item?.Benefits_Main_Description}
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              {/* <img className="phone-2" src={WantToSee} /> */}
              <img className="phone-2"
                    
                    src={current_item?.Benefits_Img}
                    alt={current_item?.Benefits_Img}
                  />
            </div>
            <div className="col-md-6 OurAdvanced align-items-center">
              <h5 className="font-weight-bold">{current_item?.Benefits_firstHeading}</h5>
              <p>
                {current_item?.Benefits_firstDescription}
              </p>
              <h5 className="font-weight-bold">{current_item?.Benefits_secondHeading}</h5>
              <p>
                {current_item?.Benefits_secondDescription}
              </p>
              <h5 className="font-weight-bold">{current_item?.Benefits_thirdeading}</h5>
              <p>
                {current_item?.Benefits_thirdDescription}
              </p>
              <h5 className="font-weight-bold">{current_item?.Benefits_fourthHeading}</h5>
              <p>
                {current_item?.Benefits_fourthDescription}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="container my-5">
          <div className="hero-section2">
            {/* style={{ backgroundImage: "url('')" }} */}
            <div className="overlay"></div>
            <div className="hero-content">
              <div className="row">
                <div className="col">

                </div>
              </div>
              <h2>TecHGV’s Advanced tracking solutions<br/>empower businesses</h2>
              <div className="mt-4">
                <a src="#" className="btn btn-custom px-4 me-2">
                  Get Started
                </a>
                <a
                  src="#"
                  className="btn btn-outline-light btn-custom-outline px-4"
                >
                  Get a Demo 
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Outlet />
    </>
  );
};

export default Industries;
